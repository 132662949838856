<p-dialog header="Conversation" [modal]="true" [(visible)]="showConversationDialog">
  <ng-template pTemplate="header">
    <p-avatar icon="pi pi-comment" styleClass="mr-2" shape="circle" />
    <span class="font-bold white-space-nowrap m-0">Conversation #: {{ conversation.id }}</span>
    <p-button icon="pi pi-bell" [text]="true" label="{{ conversation.status }}" severity="success" class="mr-2" />
    <p-button icon="pi pi-check" [rounded]="true" [text]="true" severity="info" /><strong class="mr-2">Order ID:</strong> {{ conversation.orderId }}
    <strong class="ml-4 mr-2">Created Time: </strong> {{ conversation.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}
    <strong class="ml-2 mr-2">Modified Time: </strong> {{ conversation.modified_time | date : "MMM dd, yyyy hh:mm:ss a" }}
  </ng-template>
  <ng-template pTemplate="content">
    <p-toolbar>
      <div class="p-toolbar-group-start editor-button">
        <div class="mr-2">
          <p-editor [(ngModel)]="commentEditor" [style]="{ height: '50px', 'min-width': '900px' }" />
        </div>
        <div>
          <p-button icon="pi pi-send" label="Add Comment" severity="success" class="p-button-success" (click)="sendNewConversationComment()" />
        </div>
      </div>
    </p-toolbar>
    <p-card>
      <div class="card-conv">
        <p-table [value]="conversation.comments" [paginator]="false" [scrollable]="true" scrollHeight="flex" styleClass="p-datatable-striped">
          <ng-template pTemplate="body" let-i>
            <tr class="data-row">
              <p-avatar icon="pi pi-comment" styleClass="mr-2" shape="circle" severity="danger" />
              <strong>
                {{ i.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}
                -
                {{ i.created_by }}
              </strong>
              <p-panel>
                <div [innerHTML]="i.comment"></div>
              </p-panel>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-card>
  </ng-template>
</p-dialog>

<p-card>
  <div class="grid">
    <div class="col">
      <p><strong>Order #:</strong></p>
      <span style="color: blue; font-size: x-large; font-weight: bold">{{ order.id }}</span>
      @if(order.is_priority) {
      <br />
      <p-tag class="mr-1" severity="danger" value="Priority" />
      } @if(order.is_oversize) {
      <p-tag severity="danger" value="Overside" />
      }
    </div>
    <div class="col">
      <p><strong>Total Item:</strong></p>
      <span style="color: red; font-size: x-large; font-weight: bold">{{ order.totalItem }}</span>
    </div>
    <div class="col">
      <p><strong>Conversation:</strong></p>
      <p-button
        icon="pi pi-comments"
        [text]="true"
        [rounded]="true"
        [outlined]="true"
        severity="warning"
        label=" {{ commentCount }}"
        pTooltip="View/Add comment to conversation"
        tooltipPosition="left"
        (click)="openConversationDialog()"
        styleClass="custom-conversation"
      />
    </div>
    <div class="col">
      <p><strong>Created Time:</strong></p>
      <p>{{ order.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}</p>
    </div>
    <div class="col">
      <p><strong>Seller:</strong></p>
      <p>{{ sellerInfo }}</p>
    </div>
    <div class="col">
      <p><strong>Staff:</strong></p>
      <p>{{ staffInfo }}</p>
    </div>
    <div class="col">
      <p><strong>Tracking ID:</strong></p>
      <p>
        <a href="{{ order.shipping_label }}" pTooltip="View Raw label in a new tab" tooltipPosition="top" target="_blank">{{ order.tracking_number }}</a>
      </p>
    </div>
    <div class="col">
      <p><strong>Order Status:</strong></p>
      <p>
        <span style="color: blue; font-weight: bold" class="mr-2">{{ order.status }}</span>
      </p>
    </div>
    <div class="col" style="align-content: center">
      <p-button *ngIf="order.status !== 'Shipped'" label="Confirm Shipped and Print Label" (click)="shipOrder()" class="p-button-info"></p-button>
      <p-button *ngIf="order.status === 'Shipped'" label="Reprint Label" (click)="printOrder()" class="p-button-info"></p-button>
    </div>
  </div>
  <p-divider />
  <div class="card">
    <p-table [value]="order.order_variants" [paginator]="false" [scrollable]="true" scrollHeight="flex">
      <ng-template pTemplate="body" let-orderVariant>
        <tr class="data-row">
          <td>
            <div class="grid">
              <div class="col-2 thumbnail-mockup">
                <app-image
                  [src]="orderVariant.mockup_front"
                  [text]="'mockup_front'"
                  [context]="{ orderId: order.id, orderVariantId: orderVariant.variant.id, retainUrl: true }"
                  [width]="261"
                  [height]="261"
                />
              </div>
              <div class="col-2 thumbnail-mockup">
                <app-image
                  [src]="orderVariant.mockup_back"
                  [text]="'mockup_back'"
                  [context]="{ orderId: order.id, orderVariantId: orderVariant.variant.id, retainUrl: true }"
                  [width]="261"
                  [height]="261"
                />
              </div>
              <div class="col-8">
                <div class="grid">
                  <div class="col-6 order-text">
                    <p-tag severity="warning" value="Qty: {{ orderVariant.quantity }}" />
                    {{ orderVariant.product_name }}
                  </div>
                  <div class="col-6 order-text">
                    <p-tag severity="info" value="Variant ID: {{ orderVariant.variant.id }}" />
                    {{ orderVariant.variant.style }} - {{ orderVariant.variant.color }} -
                    {{ orderVariant.variant.size }}
                  </div>
                  <div class="col-12">
                    <div class="grid">
                      <div class="col-12">
                        <div class="grid">
                          <div class="col-3" *ngFor="let image of orderVariant.print_files">
                            <div class="thumbnail-variant">
                              <p-tag severity="contrast" value="{{ image.name | uppercase }}" />
                              <app-image
                                class="cursor-pointerml-2"
                                [width]="160"
                                [height]="160"
                                [text]="image.name"
                                [context]="{ orderId: order.id, orderVariantId: orderVariant.variant.id }"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-card>
<p-toast></p-toast>
