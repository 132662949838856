<app-user-management-toolbar></app-user-management-toolbar>
<br />
<p-toolbar>
  <div class="p-toolbar-group-start">
    <p-button icon="pi pi-plus" label="Create User" severity="success" class="p-button-success" (click)="navigate()" />
  </div>
  <div class="p-toolbar-group-center">
    <div class="p-field-row">
      <input type="text" pInputText #keywordInput (keydown.enter)="onEnter($event)" placeholder="Enter keyword to search" class="mr-2" />
      <p-button icon="pi pi-search-plus" label="Search" severity="success" (click)="onEnter(keywordInput.value)" class="p-button-success" />
    </div>
  </div>
  <div class="p-toolbar-group-end"></div>
</p-toolbar>
<p-card>
  <p-table [value]="users" class="p-datatable-sm">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="UserName">UserName <p-sortIcon field="brand"></p-sortIcon></th>
        <th pSortableColumn="Full Name">Name <p-sortIcon field="name"></p-sortIcon></th>
        <th>Role</th>
        <th>Email</th>
        <th>Registration Date</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
      <tr class="data-row">
        <td>{{ user.username }}</td>
        <td>{{ user.firstname }} {{ user.lastname }}</td>
        <td>{{ user.rolename }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.createdat | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
        <td>{{ user.status }}</td>
        <td>
          <button
            *ngIf="user.username !== 'system'"
            pButton
            type="button"
            icon="pi pi-pencil"
            pTooltip="Update"
            tooltipPosition="top"
            class="p-button-rounded p-button-success p-button-text"
            (click)="openUserEdit(user)"
          ></button>
          <button
            *ngIf="user.username !== 'system'"
            pButton
            type="button"
            icon="pi pi-trash"
            pTooltip="Delete"
            tooltipPosition="top"
            severity="danger"
            [outlined]="true"
            class="p-button-rounded p-button-danger p-button-text"
            (click)="openDeleteDialog(user)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
    [rows]="pageSize"
    [totalRecords]="totalItems"
    [showFirstLastIcon]="true"
    (onPageChange)="onPage($event)"
    [showCurrentPageReport]="true"
  ></p-paginator>
  <p-confirmDialog [style]="{ width: '30vw' }"></p-confirmDialog>
</p-card>
<p-toast position="top-center"></p-toast>
