<app-user-management-toolbar></app-user-management-toolbar>
<br />
<p-table [value]="permissions" [paginator]="false" [rows]="10" [scrollable]="true" [scrollHeight]="'700px'">
    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th *ngFor="let role of roles">{{ role.name }}</th>
        </tr>
        <tr>
            <th></th>
            <th *ngFor="let role of roles">
                <p-checkbox 
                [(ngModel)]="checkAll[role.name]" 
                [binary]="true" 
                (onChange)="toggleCheckAll(role.name)">
              </p-checkbox>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-permission>
        <tr>
            <td>{{ permission.name  }}</td>
            <td *ngFor="let role of roles">
                <p-checkbox [binary]="true" inputId="binary" (onChange)="onPermissionChange(role.name)" [(ngModel)]="rolePermissionMap[role.name][permission.name]"
                    [ngModelOptions]="{standalone: true}"></p-checkbox>
            </td>
        </tr>
    </ng-template>
</p-table>
<br />
<p-button icon="pi pi-save" label="Save Permission" severity="success" class="p-button-success"
    (click)="savePermission()" />
<p-toast position="top-center"></p-toast>
<br />