import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Setting } from '../models/setting.model';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private apiUrl = `${environment.apiUrl}/setting`;

  constructor(private http: HttpClient) { }

  findAllSettings(): Observable<Setting[]> {
    return this.http.get<Setting[]>(`${this.apiUrl}/findAll`);
  }

  updateSetting(s: Setting): Observable<Setting> {
    return this.http.post<Setting>(`${this.apiUrl}/update`, s);
  }

  readonly getSetting = (key: string): Observable<any> => {
    return this.http.get(`${this.apiUrl}/${key}`).pipe(
      switchMap((({ value }: any) => {
        const values = value.split(/;\s?/g);
        return of(values)
      }))
    );
  };
}
