import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { User } from '../models/user.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject: Subject<User | null>;
  user: Observable<User | null>;
  private apiUrl = `${environment.apiUrl}/user`;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient, private router: Router) {
    this.userSubject = new Subject();
    if (isPlatformBrowser(this.platformId)) {
      const userInfo = JSON.parse(localStorage.getItem('ez-user')!);
      this.userSubject.next(userInfo);
    }
    this.user = this.userSubject.asObservable();
  }

  get userSession() {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem('ez-user')!);
    }
    return null;
  }

  signIn(userName: string, password: string) {
    const url = `${this.apiUrl}/login`;
    return this.http.post<any>(url, { userName, password }).pipe(
      map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('ez-user', JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        }
      })
    );
  }

  signUp(user: any) {
    const url = `${this.apiUrl}/addUser`;
    return this.http.post(url, user);
  }

  activateUser(data: any) {
    const url = `${this.apiUrl}/activate`;
    return this.http.post(url, data);
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('ez-user');
      this.userSubject.next(null);
    }
  }
}
