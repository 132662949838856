import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { PermissionDto } from "../models/user.permission.model";

@Injectable({ providedIn: 'root' })
export class UserManagementService {
    private apiUrl = `${environment.apiUrl}`;
    constructor(private http: HttpClient) { }

    getRoles() {
        const url = `${this.apiUrl}/role`;
        return this.http.get(url)
    }

    addOrUpdateUser(data: any) {
        if (!data.id) {
            const url = `${this.apiUrl}/user/addUser`;
            return this.http.post(url, data)
        }
        else {
            const url = `${this.apiUrl}/user/update`;
            return this.http.put(url, data)
        }
    }

    getUsers(page: number, size : number, searchKeyword: string) {
        const url = `${this.apiUrl}/user?page=${page}&size=${size}&searchKeyword=${searchKeyword}`;
        return this.http.get(url);
    }

    getUserById(id: number) {
        const url = `${this.apiUrl}/user/detail/${id}`;
        return this.http.get(url);
    }

    deleteUser(id: number) {
        const url = `${this.apiUrl}/user/${id}`;
        return this.http.delete(url);
    }

    getUserRolePermissions() {
        const url = `${this.apiUrl}/permission/user-role-permissions`;
        return this.http.get(url);
    }

    saveUserRolePermissions(permissionDto: any) {
        const url = `${this.apiUrl}/permission/save-user-role-permissions`;
        return this.http.post(url, permissionDto);
    }

    saveRole(role: any) {
        const url = `${this.apiUrl}/role/save`;
        return this.http.post(url, role);
    }

    deleteRole(id: number) {
        const url = `${this.apiUrl}/role/delete/${id}`;
        return this.http.delete(url);
    }
}