import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuditTrailQuery, AuditTrail } from '../models/audit.model';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private apiUrl = `${environment.apiUrl}/audit`;

  constructor(private http: HttpClient) {
  }

  findAuditTrails(query: AuditTrailQuery): Observable<{ total: number; data: AuditTrail[] }> {
    const params = Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&');
    return this.http.get(`${this.apiUrl}/get-audit-trails?${params}`).pipe(
      map((response: any) => response),
    );
  }

  getAuditEntities(): Observable<any> {
    return this.http.get(`${this.apiUrl}/get-audit-entities`).pipe(
      map((response) => response),
    );
  }

  getAuditActions(): Observable<any> {
    return this.http.get(`${this.apiUrl}/get-audit-actions`).pipe(
      map((response) => response),
    );
  }

  getAuditUsers(): Observable<any> {
    return this.http.get(`${this.apiUrl}/get-audit-users`).pipe(
      map((response) => response),
    );
  }
}
