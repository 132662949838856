import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, of, switchMap, tap } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    apiUrl: string;
    caches: Map<string, any> = new Map();

    timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

    constructor(private http: HttpClient) {
        this.apiUrl = `${environment.apiUrl}/dashboard`;
    }

    getOverallStatistics = () => {
        return this.http.get(`${this.apiUrl}/get-overall-statistics?timezone=${this.timezone}`).pipe(
            map((data: any) => {
                const mapping: any = {
                    newOrders: 'New Orders',
                    inProgressOrders: 'In-Progress',
                    completedOrders: 'Completed'
                };

                return Object.keys(data).map((key: string) => {
                    const [thisWeek, lastWeek] = data[key].sort((a: any, b: any) => b.week.localeCompare(a.week));
                    const delta = thisWeek.count && lastWeek?.count ? (thisWeek.count / lastWeek.count - 1) * 100 : 0
                    return {
                        name: mapping[key],
                        value: thisWeek.count,
                        delta,
                        lastWeekValue: lastWeek?.count || 0
                    }
                })
            })
        );
    };

    getChartDataPoints = (from: string, to: string | undefined, status: 'new' | 'in-progress') => {
        return of(this.caches)
            .pipe(
                switchMap((data: Map<string, any>) => {
                    if (!to) {
                        to = from;
                    }

                    if (data.has(`getChartDataPoints-${from}-${to}-${status}`)) {
                        return of(data.get(`getChartDataPoints-${from}-${to}-${status}`));
                    }
                    return this.http.get(`${this.apiUrl}/get-chart-data-points?from=${from}&to=${to}&timezone=${this.timezone}&status=${status}`)
                        .pipe(
                            map(({ data }: any) => data.map(({ time, count }: any) => [time, count])),
                            tap((value: any) => {
                                this.caches.set(`getChartDataPoints-${from}-${to}-${status}`, value);
                            })
                        );
                })
            );
    };

    getDataForWidgets = () => this.http.get(`${this.apiUrl}/get-statistic-data-for-widgets?timezone=${this.timezone}`).pipe(
        map((data: any) => {
            return Object.keys(data).reduce((acc: any, cur) => {
                Object.keys(data[cur]).forEach(key => {
                    acc[`${cur}.${key}`] = data[cur][key]
                });
                return acc;
            }, {})
        })
    );

    getSellerDataStat = (from: string, to: string | undefined) => {
        return of(this.caches)
            .pipe(
                switchMap((data: Map<string, any>) => {
                    if (!to) {
                        to = from;
                    }

                    if (data.has(`getSellerDataStat-${from}-${to}`)) {
                        return of(data.get(`getSellerDataStat-${from}-${to}`));
                    }
                    return this.http.get(`${this.apiUrl}/get-statistic-for-seller?from=${from}&to=${to}&timezone=${this.timezone}`)
                        .pipe(
                            map(({ total_orders, ...obj }: any) => ({ ...obj, totalOrders: total_orders })),
                            tap((value: any) => {
                                this.caches.set(`getSellerDataStat-${from}-${to}`, value);
                            })
                        );
                })
            );
    };
}
