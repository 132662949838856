<div class="flex justify-content-end m-2">
  <p-button [raised]="true" label="Create Label for Selected Orders" [disabled]="!selectedOrders.length" severity="info" (click)="createLabelsForRequests()" />
</div>
<p-table
  [value]="createLabelRequests"
  [(selection)]="selectedOrders"
  dataKey="orderId"
  [tableStyle]="{ 'min-width': '50rem' }"
  [responsiveLayout]="'scroll'"
  (onHeaderCheckboxToggle)="onHeaderCheckboxToggle()"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
      <th>Order ID</th>
      <th>Status</th>
      <th>Tracking Number</th>
      <th>Message</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-request let-rowIndex="rowIndex">
    <tr [pSelectableRow]="request" [pSelectableRowIndex]="rowIndex" class="data-row">
      <td>
        <p-tableCheckbox [value]="request" *ngIf="!request.disabled" />
      </td>
      <td>{{ request.orderId }}</td>
      <td>
        <div [ngSwitch]="request.status?.toLowerCase()">
          <p-progressBar *ngSwitchCase="'requesting label creation'" mode="indeterminate" [style]="{ height: '6px', width: '200px' }"></p-progressBar>
          <p-progressBar *ngSwitchCase="'queued'" [value]="0" [style]="{ height: '6px', width: '200px' }"></p-progressBar>
          <p-progressBar *ngSwitchDefault [value]="100" [style]="{ width: '200px' }" />
          {{ request.status }}
        </div>
      </td>
      <td>
        <a *ngIf="request.labelUrl" [href]="request.labelUrl" target="_blank" rel="noopener noreferrer">
          {{ request.trackingNumber }}
        </a>
        <span *ngIf="!request.labelUrl">No Label</span>
      </td>
      <td>{{ request.message }}</td>
    </tr>
  </ng-template>
</p-table>
