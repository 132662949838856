export interface WalletTransaction {
  id: number;
  type: string;
  user_name: string;
  transfer_type: string;
  balance: number;
  amount: number;
  change_by: string;
  status: string;
  note: string;
  created_time: Date;
  modified_time: Date;
}

export interface WalletUserBalance {
  id: number;
  user_name: string;
  balance: number;
  latest_transaction_id: number;
  created_time: Date;
  modified_time: Date;
}

export enum TransactionType {
  FUND = 'fund',
  ACTIVITY = 'activity',
}

export enum TransactionStatus {
  REQUESTED = 'Requested',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
}
