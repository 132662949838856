<table class="w-full" style="border-collapse: collapse; table-layout: auto;">
    <thead>
        <tr>
            @for(col of cols; track col.field; let first = $first) {
            <th class="font-semibold py-3 border-bottom-1 surface-border white-space-nowrap"
                [class]="{'text-left': first}">
                {{col.header}}
            </th>
            }
        </tr>
    </thead>
    <tbody>
        @for(parent of items; track parent.data.name) {
        <tr>
            <td [attr.colspan]="cols.length"
                class="text-left py-3 border-bottom-1 surface-border white-space-nowrap text-lg">
                {{parent.data.name}}
            </td>
        </tr>
        @for(child of parent.children; track child.data.name; let last = $last){
        <tr>
            @for(col of cols; track col.field; let first = $first) {
            <td class="text-right py-3 surface-border" [class]="{'px-3': !first, 'border-bottom-1': last}">
                @if(col.type === 'currency') {
                {{child.data[col.field] | currency: 'USD'}}
                <span
                    *ngIf="child.data.oversizePrice && col.field === 'oversizePrice'">({{child.data.name.split('+').length}}
                    print{{child.data.name.split('+').length > 1 ? 's' : ''}})
                </span>
                } @else {
                {{child.data[col.field]}}
                }
            </td>
            }
        </tr>
        }
        }
    </tbody>
</table>