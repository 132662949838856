<app-product-toolbar></app-product-toolbar>
<br />
<p-toolbar>
  <p-button icon="pi pi-plus" label="New Tier" severity="success" class="p-button-success" (click)="onShowNewPriceTierDialog()" />
</p-toolbar>
<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '30vw' }"></p-confirmDialog>
<p-dialog header="Create New Price Tier" [modal]="true" [(visible)]="showNewPriceTierDialog">
  <div class="flex align-items-center gap-3 mb-3">
    <label for="name" class="font-semibold w-8rem">Tier Name</label>
    <input id="length" pInputText [(ngModel)]="newPriceTier.name" [style]="{ 'min-width': '300px' }" />
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label for="description" class="font-semibold w-8rem">Description</label>
    <input id="length" pInputText [(ngModel)]="newPriceTier.description" [style]="{ 'min-width': '300px' }" />
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label class="font-semibold w-8rem"></label>
    <p-button icon="pi pi-save" label="Save" (onClick)="onCreateNewPriceTier()"></p-button>
    <p-button icon="pi pi-times-circle" label="Cancel" severity="secondary" (onClick)="showNewPriceTierDialog = false"></p-button>
  </div>
</p-dialog>

<p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{ height: '3px' }" />
<div class="card">
  <p-table [value]="pricingTiers" dataKey="id" [scrollable]="true" scrollHeight="flex">

    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Description</th>
        <th>Created Time</th>
        <th>Modified Time</th>
        <th>Action</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-tier>
      <tr class="data-row">
        <td>{{ tier.id }}</td>
        <td>{{ tier.name }}</td>
        <td>{{ tier.description }}</td>
        <td>{{ tier.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
        <td>{{ tier.modified_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
        <td>
          <button
            *ngIf="(tier.name | uppercase) !== 'STANDARD'"
            pButton
            type="button"
            icon="pi pi-trash"
            pTooltip="Delete"
            tooltipPosition="top"
            severity="danger"
            [outlined]="true"
            class="p-button-rounded p-button-danger p-button-text"
            (click)="onDeleteTier(tier)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
