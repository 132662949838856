import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const phoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const phoneRegex = /^(?:\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
    if (!control || control.value == '') {
        return null;
    }
    var isValid =  phoneRegex.test(control.value);

    return isValid ? null : { invalidPhone: true }
}