import { Component, EventEmitter, Input } from '@angular/core';
import { SharePrimeNGModule } from '../../../share-primeng.module';
import { Observable } from 'rxjs';

interface ToolbarButtonModel {
  label: string,
  icon: string,
  severity: 'success' | 'danger' | 'info' | 'warning' | 'help' | 'primary' | 'secondary' | 'contrast' | null | undefined,
  event: EventEmitter<any> | Observable<any>,
  text?: boolean,
  size?: 'small' | 'large',
  disabled?: boolean,
  httpRequest?: (...args: any) => Observable<any>,
}

export interface ToolbarModel {
  leftText?: { text: string, size?: 'text-xs' | 'text-sm' | 'text-base' | 'text-lg' | 'text-xl' | 'text-2xl' | 'text-3xl' | 'text-4xl' },
  leftButtons?: ToolbarButtonModel[],
  rightButtons?: ToolbarButtonModel[],
};

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [
    SharePrimeNGModule
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.css'
})
export class ToolbarComponent {
  @Input()
  configs: ToolbarModel | any = {};
}
