import { Component, EventEmitter } from '@angular/core';
import { ColumnModel, FilterConfigModel, TYPES } from '../shared-ui/common/table/table.model';
import { SharePrimeNGModule } from '../share-primeng.module';
import { TableComponent } from '../shared-ui/common/table/table.component';
import { ToolbarModel } from '../shared-ui/common/toolbar/toolbar.component';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ReportService } from '../services/report.service';
import { BehaviorSubject, combineLatest, map, Subscription, switchMap, tap } from 'rxjs';
import { CommonService } from '../services/common.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [SharePrimeNGModule, TableComponent],
  templateUrl: './report.component.html',
  styleUrl: './report.component.css',
  providers: [MessageService, CurrencyPipe],
})
export class ReportComponent {
  rows = 20;
  totalRecords: number = 0;
  cols: ColumnModel[] = [
    { field: 'id', header: 'ID', width: '5%', sortable: true, template: 'idColTemplate' },
    { field: 'isOversize', header: 'Is Oversize', width: '5%', template: 'booleanTemplate' },
    { field: 'price_priority', header: 'Priority Price', width: '5%', template: 'simpleCurrencyTemplate' },
    { field: 'printCost', header: 'Print Cost', width: '5%', template: 'simpleCurrencyTemplate' },
    { field: 'shippingPrice', header: 'Shipping Price', width: '5%', template: 'simpleCurrencyTemplate' },
    { field: 'totalCost', header: 'Total Cost', width: '5%', template: 'simpleCurrencyTemplate' },
    { field: 'sellerPaid', header: 'Seller Paid', width: '5%', template: 'simpleCurrencyTemplate' },
    { field: 'created_time', header: 'Created Time', width: '10%', template: 'dateTemplate' },
  ];

  syncButton: any = {
    label: 'Sync',
    icon: 'pi-sync',
    severity: 'info',
    text: true,
    event: new EventEmitter(),
  };

  toolbarConfigs: ToolbarModel = {
    leftText: {
      text: '',
    },
    rightButtons: [
      {
        label: 'Show Filter',
        icon: 'pi-filter',
        severity: 'secondary',
        event: new EventEmitter(),
      },
      this.syncButton,
    ],
  };

  sellerFilter: FilterConfigModel<'seller'> = {
    label: 'Select a Seller',
    type: TYPES.DROPDOWN,
    optionLabel: 'seller',
    hasFilter: true,
    options: [],
    key: 'seller',
    occupy: 4,
  };

  storeFilter: FilterConfigModel<'store'> = {
    label: 'Select a Team',
    type: TYPES.DROPDOWN,
    optionLabel: 'store',
    hasFilter: true,
    options: [],
    key: 'store',
    occupy: 4,
  };

  filterConfigs: FilterConfigModel<any>[] = [
    {
      label: 'Order ID',
      type: TYPES.INPUT,
      key: 'orderId',
      occupy: 4,
    },
    {
      label: 'Ref ID',
      type: TYPES.INPUT,
      key: 'ref_id',
      occupy: 4,
    },
    {
      label: 'From - To',
      type: TYPES.DATERANGE,
      key: 'fromTo',
      occupy: 4,
    },
    this.sellerFilter,
    this.storeFilter,
    {
      type: TYPES.BUTTONGROUP,
      buttons: [
        {
          label: 'Clear filters',
          severity: 'secondary',
          action: (formGroup: FormGroup) => {
            formGroup.reset();

            const filterValue = this.filter$.getValue();
            this.filter$.next({
              ...filterValue,
              conditions: {},
            });
          },
        },
        {
          label: 'Filter',
          severity: 'primary',
          action: (formGroup: FormGroup) => {
            const {
              orderId,
              refId,
              seller,
              store,
              fromTo: [fromTime, toTime],
            } = formGroup.getRawValue();

            const filterValue = this.filter$.getValue();

            this.filter$.next({
              ...filterValue,
              skip: 0,
              conditions: {
                orderId: orderId === '' ? undefined : orderId,
                refId,
                seller,
                store,
                fromTime,
                toTime: toTime || new Date(),
              },
            });
          },
        },
      ],
      occupy: 4,
    },
  ];

  orders = [];

  filter$: BehaviorSubject<any> = new BehaviorSubject({ take: this.rows });

  subscriptions: Subscription = new Subscription();

  constructor(
    private reportService: ReportService,
    private commonService: CommonService,
    private messageService: MessageService,
    private currencyPipe: CurrencyPipe,
  ) {
    const toogleSyncButtonState = (isSyncing: boolean) => {
      if (isSyncing) {
        this.syncButton.icon += ' pi-spin';
        this.syncButton.disabled = true;
        return;
      }

      this.syncButton.icon = this.syncButton.icon.replace(/\spi-spin/g, '');
      this.syncButton.disabled = false;
    };

    this.subscriptions.add(
      this.syncButton.event
        .pipe(
          tap(() => {
            toogleSyncButtonState(true);
            this.filter$.next(this.filter$.getValue());
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      combineLatest([
        this.filter$.pipe(
          tap(() => {
            this.commonService.isLoading$.next(true);
            toogleSyncButtonState(true);
          }),
          switchMap((filter) =>
            combineLatest([
              this.reportService.getReportsByFilter(filter).pipe(
                map(({ totalRecords, data }: any) => ({
                  totalRecords,
                  data: data.map(({ priceBreakdown, ...item }: any) => ({ ...item, ...priceBreakdown })),
                }))
              ),
              this.reportService.getTotalAmountAndQuantityOrder(),
            ])
          )
        ),
        this.reportService.getStoreAndSellerOptions(),
      ])
        .pipe(
          tap(() => {
            this.commonService.isLoading$.next(false);
            toogleSyncButtonState(false);
          }),
          map(([[orders, { total_orders, total_amount }], { sellers, stores }]: any) => [orders, { total_orders, total_amount }, { sellers, stores }])
        )
        .subscribe(([{ totalRecords, data }, { total_orders, total_amount }, { sellers, stores }]: any) => {
          this.orders = data;
          this.totalRecords = totalRecords;
          this.toolbarConfigs.leftText!.text = `Total orders: ${total_orders} - Total amount: ${this.formatAmount(total_amount)}`;

          (this.sellerFilter as any).options = [
            {
              seller: '-',
              value: undefined,
            },
            ...sellers,
          ];

          (this.storeFilter as any).options = [
            {
              store: '-',
              value: undefined,
            },
            ...stores,
          ];
        })
    );
  }

  formatAmount(amount: number) {
    return this.currencyPipe.transform(amount, 'USD', 'symbol');
  }

  pageChange(event: any) {
    const filterValue = this.filter$.getValue();
    this.filter$.next({
      ...filterValue,
      skip: event.first,
      take: event.rows,
    });
  }

  sorting(event: any) {
    const filterValue = this.filter$.getValue();
    this.filter$.next({
      ...filterValue,
      skip: 0,
      sort: event.order === 1 ? 'ASC' : 'DESC',
    });
  }
}
