import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { isPhoneNumber, isPostalCode } from 'class-validator';

export const quantityGreaterThanValidator = (value: number, listName: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
        if (control.value.length < value) {
            return { message: `Need at least ${value} item${value > 1 ? 's' : ''} in the ${listName}` };
        }

        return {};
    };
};

export const atLeastOneIn = (properties: string[] = []): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
        const formGroup = control as FormGroup<any>;
        if (properties.every(prop => !formGroup?.get(prop)?.value)) {
            return { message: `Need at least ${properties.join(' or ')} has value` }
        }

        return {};
    };
};

export const notEqualTo = (value: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
        if (control.value?.toLowerCase().includes(value)) {
            return { message: `Is pending` };
        }

        return {};
    };
};

export const notRequiredButShouldBeInType = (type: 'words' | 'postcode' | 'phonenumber' = 'words'): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
        const value = control.value;
        if (!value) {
            return {};
        }

        const regexByType = {
            words: (value: string): boolean => /^[A-Za-z\s]+$/.test(value),
            postcode: (value: string): boolean => isPostalCode(value, 'US'),
            phonenumber: (value: string): boolean => isPhoneNumber(value, 'US'),
        };

        const validatorFn: any = regexByType[type];

        if (validatorFn && !validatorFn(value)) {
            return { message: `Invalid value` };
        }

        return {};
    }
};

export const shouldBeAsAPair = (...pairs: string[][]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
        const formGroup = control as FormGroup<any>;

        if (pairs.every(properties => {
            const [firstProp, secondProp] = properties;
            return !formGroup?.get(firstProp)?.value !== !formGroup?.get(secondProp)?.value;
        })) {
            return { message: `Need to fill two fields` };
        }

        return {};
    };
};

export const shouldBeRequiredIfOtherFieldsHasValue = (fields: string[]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
        if (fields.every(field => control.root.get(field)?.value)) {
            if (!control.value) {
                control.setValue('INVALID TRACKING LABEL');
                return { message: `Should not be empty` };
            }
        }

        return {};
    };
};

