<app-loading-overlay [loading]="isLoading$" [autoHide]="10000"></app-loading-overlay>
<div
  class="layout-wrapper layout-sidebar-darkgray layout-static"
  [class]="{ 'layout-static-inactive': isMobile || !leftNavVisible, 'layout-mobile-active': isMobile && leftNavVisible }"
>
  <div class="layout-content-wrapper">
    <div class="layout-topbar">
      <div class="topbar-left">
        <a pRipple href="javascript:void(0)" class="menu-button" (click)="leftNavVisible = !leftNavVisible">
          <i style="color: forestgreen" class="pi" [ngClass]="leftNavVisible ? 'pi-chevron-left' : 'pi-chevron-right'"></i>
        </a>
        <span class="topbar-separator"></span>
        <span class="text-lg uppercase">{{ title }}</span>
      </div>
      <div class="layout-topbar-menu-section">
        <div class="layout-sidebar">
          <div class="sidebar-header">
            <a href="" class="logo">
              <img class="logo-image" src="../assets/images/headers/logo.png" />
              <span class="app-name">PrintHubPod</span>
            </a>
          </div>
          <div class="layout-menu-container">
            <div class="card flex justify-content-center">
              <p-menu [model]="leftSideNav" styleClass="w-full md:w-15rem layout-menu">
                <ng-template pTemplate="submenuheader" let-item>
                  <span class="text-primary font-bold">{{ item.label }}</span>
                </ng-template>
                <ng-template pTemplate="item" let-item>
                  <a pRipple class="flex align-items-center p-menuitem-link" [class]="{ 'route-active': leftSideNavState() === item.route }">
                    <span class="layout-menuitem-icon" [class]="item.icon"></span>
                    <span class="layout-menuitem-text">{{ item.label }}</span>
                    <p-badge *ngIf="item.badge" class="ml-auto" [value]="item.badge" />
                  </a>
                </ng-template>
              </p-menu>
            </div>
          </div>
          <div class="layout-footer">
            <span class="footer-copyright">© PrintHubPod - 2024</span>
          </div>
        </div>
      </div>
      <div class="layout-mask modal-in" (click)="leftNavVisible = false"></div>
      <div class="topbar-right">
        <ul class="topbar-menu">
          <!-- <li class="search-item">
            <a href="javascript:void(0)">
              <i class="pi pi-search"></i>
            </a>
          </li>
          <li class="static sm:relative">
            <a href="javascript:void(0)">
              <i class="pi pi-bell"></i>
              <span class="topbar-badge">5</span>
            </a>
          </li> -->
          <li class="profile-item static sm:relative">
            <a pRipple (click)="menu.toggle($event)">
              <p-avatar [style]="{color: 'forestgreen'}" icon="pi pi-user" styleClass="mr-2" size="large" shape="circle" />
              <span class="profile-name">Welcome {{ username }}</span>
            </a>
            <p-menu #menu [model]="profileMenu" [popup]="true" appendTo="body" />
          </li>
          <li class="right-sidebar-item">
            <a (click)="sidebarVisible = !sidebarVisible">
              <i class="pi pi-align-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="layout-content overflow-auto">
      <div class="layout-content-container">
        <router-outlet></router-outlet>
      </div>
      <p-scrollTop target="parent" />
    </div>
  </div>
  <p-sidebar [(visible)]="sidebarVisible" [position]="'right'">
    <h3></h3>
    <p></p>
  </p-sidebar>
</div>
