import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SharePrimeNGModule } from '../../share-primeng.module';

@Component({
  selector: 'app-production',
  standalone: true,
  imports: [CommonModule, SharePrimeNGModule],
  templateUrl: './production.component.html',
  styleUrl: './production.component.css'
})
export class ProductionComponent {

}
