import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharePrimeNGModule } from '../../share-primeng.module';
import { AuthenticationService } from '../../services/authentication.service';
import { merge, of } from 'rxjs';

@Component({
  selector: 'app-front-layout',
  standalone: true,
  imports: [CommonModule, SharePrimeNGModule],
  templateUrl: './front-layout.component.html',
  styleUrl: './front-layout.component.css',
  host: { ngSkipHydration: 'true' },
})
export class FrontLayoutComponent {
  isLogged = signal(false);
  isShowMenu = false;

  topNavMenu = [
    {
      label: 'HOME',
      url: '',
    },
    {
      label: 'PRODUCTION',
      url: 'production',
    },
    {
      label: 'COST PREVIEW',
      url: 'service-catalog',
    },
    {
      label: 'API INTEGRATION',
      url: 'api-integration',
    },
    {
      label: 'ABOUT US',
      url: 'about-us',
    },
  ];

  topLeftNavMenu = [
    {
      label: 'CONTACT US',
      url: 'contact-us',
    },
    {
      label: 'LOGIN',
      url: 'login',
    },
    {
      label: 'SIGN UP',
      url: 'register',
    },
  ];

  topLefNavMenuLogged = [
    {
      label: 'Welcome, <username>',
      url: '',
    },
    {
      label: 'Go to Dashboard',
      url: 'dashboard',
    },
  ];

  contactInfos = [
    {
      about1: 'Our Head Office',
      at1: 'Oakwood Park, Houston, TX 77040',
    },
    {
      about1: 'Phone',
      at1: '+1(832) 858-7561',
      about2: 'Email',
      at2: 'customer-service@PrintHubPod.com',
    },
    {
      about1: 'Store Hours',
      at1: '(Central Time)',
      details: ['Mon-Fri: 8 AM - 6 PM', 'Sat: 10 AM - 5 PM', 'Sun: CLOSED'],
    },
  ];

  constructor(private authenticationService: AuthenticationService) {
    merge(this.authenticationService.user, of(this.authenticationService.userSession)).subscribe((user: any) => {
      this.isLogged.set(!!user);

      if (!user) {
        return;
      }

      const [contact] = this.topLeftNavMenu;
      const [welcome, logout] = this.topLefNavMenuLogged;
      this.topLeftNavMenu = [contact, { ...welcome, label: welcome.label.replace('<username>', user.name) }, logout];
    });
  }

  onMenuItemClick() {
    this.isShowMenu = false;
  }
}
