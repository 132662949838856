<form [formGroup]="activationForm" class="justify-content-center" style="display: flex" (ngSubmit)="activateAccount()">
    <p-card header="Activation Account" class="justify-content-center" [style]="{ width: '500px' }">
  <div class="grid">
    <div class="col-12">
      <div class="field">
        <label for="email">Email</label>
        <input
          type="text"
          pInputText
          formControlName="email"
          class="w-full h-3rem border-round-md border-1 border-primary-400"
          name="email"
          placeholder="Email"/>
        <small *ngIf="activationForm.get('email')?.invalid && (activationForm.get('email')?.dirty || activationForm.get('email')?.touched)" class="block p-error">
          <div *ngIf="activationForm.get('email')?.errors?.['required']">Email is required.</div>
        </small>
      </div>
      <div class="field">
        <label for="verificationCode">Verification Code</label>
        <input type="text" pInputText formControlName="verificationCode" class="w-full h-3rem border-round-md border-1 border-primary-400" name="verificationCode" placeholder="Verification Code"/>
        <small *ngIf="activationForm.get('verificationCode')?.invalid && (activationForm.get('verificationCode')?.dirty || activationForm.get('verificationCode')?.touched)" class="block p-error">
          <div *ngIf="activationForm.get('verificationCode')?.errors?.['required']">Verification code is required.</div>
        </small>
      </div>
      <p-button label="Activate" styleClass="w-full" [disabled]="activationForm.invalid" type="submit"></p-button>
    </div>
  </div>
  </p-card>
</form>
<br />
<p-toast position="top-center"></p-toast>
