import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, map, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { OrderCollection, OrderCollectionQuery } from '../models/mshop.model';

@Injectable({
  providedIn: 'root',
})
export class MyShopService {
  private apiUrl = `${environment.apiUrl}/myshop`;

  constructor(private http: HttpClient) {}

  checkConnectionTikTok(): Observable<any> {
    return this.http.get(`${this.apiUrl}/checkConnectionTikTok`).pipe(map((response) => response));
  }

  getMyOrderCollections(query: OrderCollectionQuery): Observable<{ total: number; data: OrderCollection[] }> {
    const params = Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&');

    return this.http.get(`${this.apiUrl}/get-my-order-collections?${params}`).pipe(map((response: any) => response));
  }

  processIncompleteCollections(): Observable<any> {
    return this.http.get(`${this.apiUrl}/process-incomplete-collections`).pipe(map((response) => response));
  }

  uploadEncodedCSVFiles(payload: { files: string[] }): Observable<any> {
    const uploadUrl = `${this.apiUrl}/upload-order-csv-files`;
    return this.http.post<any>(uploadUrl, payload);
  }

  uploadOrderCSVFiles(formData: FormData): Observable<any> {
    const uploadUrl = `${this.apiUrl}/upload-order-csv-files`;
    // Make the HTTP POST request to upload the files
    return this.http.post(uploadUrl, formData, {
      reportProgress: true,
      observe: 'events', // Monitor upload progress
    });
  }
}
