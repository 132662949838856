import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewChild } from '@angular/core';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { catchError, of } from 'rxjs';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Paginator } from 'primeng/paginator';
import { MessageService } from 'primeng/api';

import { SharePrimeNGModule } from '../share-primeng.module';
import { TrackingService } from '../services/tracking.service';
import { OrderTracking } from '../models/order.tracking.model';

@Component({
  selector: 'app-trackings',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, UpperCasePipe, ProgressSpinnerModule, SharePrimeNGModule],
  providers: [DialogService, MessageService],
  templateUrl: './trackings.component.html',
  styleUrl: './trackings.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TrackingsComponent {
  @ViewChild('paginator') paginator!: Paginator;
  searchForm: FormGroup;
  isRefreshing = false;
  displayTrackingDetailDialog = false;
  tracking_status: any;
  totalOrderTracking: number = 0;
  orderTrackings: OrderTracking[] = [];
  isLoading = true;
  trackingStatuses: any[] = [];
  trackingStatusProgress: any = {
    UNKNOWN: 15,
    PRE_TRANSIT: 20,
    DELIVERED: 100,
  };

  constructor(private trackingService: TrackingService, private fb: FormBuilder, private messageService: MessageService) {
    this.searchForm = this.fb.group({
      status: [''],
      orderId: [''],
      trackingNumber: [''],
    });
  }

  ngOnInit(): void {
    this.loadOrderTracking();
    this.loadTrackingStatus();
  }

  loadOrderTracking(offset: number = 0, limit: number = 30) {
    this.isLoading = true;
    const formValues = this.searchForm.value;
    this.trackingService
      .findOrderTrackings(offset, limit, {
        trackingStatus: formValues.status,
        orderId: formValues.orderId,
        trackingNumber: formValues.trackingNumber,
      }).pipe(
        catchError(error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error!',
            detail: `Unable to perform action: ${error}`,
          });
          return of({});
        })
      ).subscribe((data: any) => {
        this.totalOrderTracking = data.total ? data.total : 0;
        this.orderTrackings = data.data ? data.data : [];
        // Calcuate shipping progress by %
        this.calcShippingProgress();
        // Refresh the tracking status from 3rd party
        if (this.isRefreshing === true) {
          this.refreshTrackingStatus();
        }
        this.isLoading = false
      });
  }

  loadTrackingStatus() {
    this.trackingService
      .findAllTrackingStatus()
      .pipe(
        catchError(error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Load tracking status!',
            detail: `Unable to perform action: ${error}`,
          });
          return of([]);
        })
      ).subscribe(data => {
        this.trackingStatuses = data.map((status) => ({
          label: status,
          value: status,
        }));
      });
  }

  onSearch() {
    this.loadOrderTracking(0, this.paginator.rows);
  }

  onShowTrackingDetail(t: OrderTracking) {
    this.displayTrackingDetailDialog = true;
    this.tracking_status = t.order_tracking.last_tracking_status;
    this.tracking_status.trackingHistory?.sort((a: { statusDate: string }, b: { statusDate: string }) => {
      return new Date(b.statusDate).getTime() - new Date(a.statusDate).getTime();
    });
    this.tracking_status.progress_percent = t.progress_percent;
  }

  onClear() {
    this.searchForm.reset();
    this.loadOrderTracking(0, this.paginator.rows);
  }

  onRefreshCurrentView() {
    this.isRefreshing = true;

    const event = {
      first: this.paginator.first,
      rows: this.paginator.rows,
      page: this.paginator.getPage(),
      pageCount: Math.ceil(this.paginator.totalRecords / this.paginator.rows),
    };

    this.onPageChange(event);
  }

  onPageChange(event: any) {
    this.loadOrderTracking(event.page * event.rows, event.rows);
  }

  refreshTrackingStatus() {
    const orderTrackings = this.orderTrackings.map((t) => ({
      id: t.id,
    }));

    this.trackingService
      .refreshOrderTrackings(orderTrackings)
      .pipe(
        catchError((error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Refresh order tracking!',
            detail: `Unable to perform action: ${error}`,
          });

          return of([]);
        })
      ).subscribe((data) => {
        this.orderTrackings = data;
        this.calcShippingProgress();
        this.isRefreshing = false;
      });
  }

  calcShippingProgress() {
    this.orderTrackings.sort((a, b) => b.id - a.id);
    this.orderTrackings.map((t) => {
      if (t.order_tracking.status in this.trackingStatusProgress) {
        t.progress_percent = this.trackingStatusProgress[t.order_tracking.status];
      } else {
        // Only calculate actualy % when the status is not in the pre-defined list
        const lts = t.order_tracking.last_tracking_status;
        if (lts?.trackingHistory?.length) {
          const start_time = lts.trackingHistory[0].objectCreated;
          const end_time = lts.eta ? lts.eta : lts.trackingHistory[lts.trackingHistory.length - 1].objectCreated;
          const totalHours = this.calcHoursBetweenDates(new Date(start_time), new Date(end_time));
          const currentHours = this.calcHoursBetweenDates(new Date(start_time), new Date());

          t.progress_percent = Math.ceil((currentHours / totalHours) * 100);
          t.progress_percent = t.progress_percent > 95 ? 95 : t.progress_percent;
        }
      }
    });
  }

  calcHoursBetweenDates(startDate: Date, endDate: Date): number {
    const differenceInMs = endDate.getTime() - startDate.getTime();
    return differenceInMs / (1000 * 60 * 60); // Convert milliseconds to hours
  }

  getProgressColorClass(value: number): string {
    if (value <= 25) {
      return 'progress-25';
    } else if (value <= 50) {
      return 'progress-50';
    } else if (value <= 75) {
      return 'progress-75';
    } else {
      return 'progress-100';
    }
  }
}
