import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BehaviorSubject, combineLatest, switchMap, tap } from 'rxjs';
import moment from 'moment';
import { MeterItem } from 'primeng/metergroup';
import { MenuItem } from 'primeng/api';

import { CardsComponent } from '../shared-ui/common/cards/cards.component';
import { LineChartsComponent } from '../shared-ui/common/line-charts/line-charts.component';
import { SharePrimeNGModule } from '../share-primeng.module';
import { DashboardService } from '../services/dashboard.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    CardsComponent,
    LineChartsComponent,
    SharePrimeNGModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',
})
export class DashboardComponent implements OnDestroy {
  values: any[][] = [];

  menuItem: MenuItem[] = [
    {
      label: 'Today',
      icon: 'pi pi-circle-on',
      command: ({ item }: any) => {
        this.menuState = {
          from: this.getFromDate(),
          status: 'new',
          label: 'Today'
        };
        this.getMenuAction(item);
      }
    },
    {
      label: 'Yesterday',
      icon: 'pi pi-circle-off',
      command: ({ item }: any) => {
        this.menuState = {
          from: this.getFromDate(-1),
          status: 'new',
          label: 'Yesterday'
        };
        this.getMenuAction(item);
      }
    },
    {
      label: 'Last 7 days',
      icon: 'pi pi-circle-off',
      command: ({ item }: any) => {
        this.menuState = {
          from: this.getFromDate(-7),
          to: this.getFromDate(),
          status: 'new',
          label: 'Last 7 days'
        };
        this.getMenuAction(item);
      }
    },
    {
      label: 'Last 30 days',
      icon: 'pi pi-circle-off',
      command: ({ item }: any) => {
        this.menuState = {
          from: this.getFromDate(-30),
          to: this.getFromDate(),
          status: 'new',
          label: 'Last 30 days'
        };
        this.getMenuAction(item);
      }
    },
  ];

  private readonly getFromDate = (num: number = 0) => {
    if (new Date().getTimezoneOffset() < 0) {
      num += 1;
    }
    return moment.utc().local().startOf('day').add(num, 'day').toISOString();
  };

  menuState: any = {
    from: this.getFromDate(),
    status: 'new',
    label: 'Today'
  };

  selectedCharts$: BehaviorSubject<any> = new BehaviorSubject(this.menuState);

  overallStatistics = [{
    name: 'New Orders',
    value: 0,
    delta: 0,
    lastWeekValue: 0
  }, {
    name: 'In-Progress',
    value: 0,
    delta: 0,
    lastWeekValue: 0
  }, {
    name: 'Completed',
    value: 0,
    delta: 0,
    lastWeekValue: 0
  }];

  dataPoints: any[] = [];

  chartConfigs = [
    { label: 'New', status: 'new', icon: 'pi-plus-circle' },
    { label: 'In-Progress', status: 'in-progress', icon: 'pi-warehouse' },
    { label: 'Out-For-Delivery', status: 'out-for-delivery', icon: 'pi-truck' },
    { label: 'Completed', status: 'completed', icon: 'pi-check-circle' }
  ];

  cards = [
    {
      header: 'Orders',
      icon: 'pi-file-arrow-up',
      leftStatitic: {
        name: 'New',
        key: 'orders.new',
        value: 0
      },
      rightStatitic: {
        name: 'Over 2 days',
        key: 'orders.over-2-days',
        value: 0
      }
    },
    {
      header: 'Shipped Orders',
      icon: 'pi-truck',
      leftStatitic: {
        name: 'Today',
        key: 'shippedOrders.today',
        value: 0
      },
      rightStatitic: {
        name: 'Yesterday',
        key: 'shippedOrders.yesterday',
        value: 0
      }
    },
    {
      header: 'Pressed Orders',
      icon: 'pi-print',
      leftStatitic: {
        name: 'Today',
        key: 'pressedOrders.today',
        value: 0
      },
      rightStatitic: {
        name: 'Yesterday',
        key: 'pressedOrders.yesterday',
        value: 0
      }
    },
    {
      header: 'Efficiency (In-Avrg)',
      icon: 'pi-chart-line',
      leftStatitic: {
        name: 'Today',
        key: 'efficiency.today',
        value: 0
      },
      rightStatitic: {
        name: 'Yesterday',
        key: 'efficiency.yesterday',
        value: 0
      }
    },
  ];

  totalSellerOrders: number = 0;

  constructor(private dashboardService: DashboardService, private commonService: CommonService) {
    combineLatest([
      this.dashboardService.getOverallStatistics(),
      this.selectedCharts$.pipe(
        tap(() => this.commonService.isLoading$.next(true)),
        switchMap((data: any) => {
          const { from, to, status } = data;
          return combineLatest([
            this.dashboardService.getChartDataPoints(from, to, status),
            this.dashboardService.getSellerDataStat(from, to),
          ]);
        })
      ),
      this.dashboardService.getDataForWidgets()
    ]).pipe(
      tap(() => this.commonService.isLoading$.next(false))
    ).subscribe(([data, [dataPoints, sellerStats], widgetsData]) => {
      this.totalSellerOrders = sellerStats.totalOrders;
      this.values = sellerStats.data.map((item: any) => [
        {
          label: item.store_name,
          actual: item.count,
          value: (item.count * 100 / sellerStats.totalOrders).toFixed(2),
          color: `#${(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')}`
        }
      ]).sort((a: any, b: any) => b.actual - a.actual)
      // [{ label: 'seller_1', value: 15, color: '#64B5F6' }]

      this.overallStatistics = data;
      this.dataPoints = dataPoints;
      this.cards = this.cards.map(({ leftStatitic, rightStatitic, ...item }) => {
        [leftStatitic, rightStatitic].forEach(stat => {
          stat.value = widgetsData[stat.key];
        });

        return {
          ...item,
          leftStatitic,
          rightStatitic
        };
      });
    });
  }

  ngOnDestroy(): void {
    this.dashboardService.caches.clear();
  }

  getMenuAction = (item: any) => {
    this.menuItem = this.menuItem.map(({ icon, ...obj }: any) => {
      if (item.label === obj.label) {
        return {
          ...obj,
          icon: icon.replace(/-off/g, '-on')
        }
      }
      return {
        ...obj,
        icon: icon.replace(/-on/g, '-off'),
      };
    });

    this.selectedCharts$.next(this.menuState);
  };

  onSelectChart(status: string) {
    this.menuState = { ...this.menuState, status };
    this.selectedCharts$.next(this.menuState);
  }
}
