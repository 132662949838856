import { Injectable } from '@angular/core';
import { distinctUntilChanged, of, BehaviorSubject, switchMap, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  width$ = new Subject<number>();
  isDesktop$ = new BehaviorSubject<boolean>(true);
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.width$.pipe(
      switchMap(value => of(value > 991)),
      distinctUntilChanged()
    ).subscribe(isDesktop => this.isDesktop$.next(isDesktop));
  }
}