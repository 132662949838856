import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control) {
        return null;
    }
    const value = control.value || '';
    const minLength = value.length >= 8;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasDigit = /\d/.test(value);
    const hasSpecialChar = /[\W_]/.test(value);
    const errors = {
        minLength: !minLength,
        noUpperCase: !hasUpperCase,
        noLowerCase: !hasLowerCase,
        noDigit: !hasDigit,
        noSpecialChar: !hasSpecialChar,
    }; 

    return Object.values(errors).some(Boolean) ? errors : null;
}