import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class ContactUsService {
    private apiUrl = `${environment.apiUrl}/contact-us`;
    constructor(private http: HttpClient) {}

    submit(contactUs: any){
        const url = `${this.apiUrl}/submit`;
        return this.http.post(url, contactUs)
    }

}