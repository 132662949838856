import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    DEFAULT_MAPPING = {
        match: '',
        fn: (url: string) => url
    };
    LABEL_MAPPINGS$ = new BehaviorSubject<any[]>([]);

    constructor(private http: HttpClient) { }

    lookupReplacementUrl(url: string): Observable<(url: string) => string> {
        const lookupFn = (source: any[]) => {
            const { fn } = source.find(({ match }) => url.includes(match)) || {};
            return fn;
        };

        const fn: any = lookupFn(this.LABEL_MAPPINGS$.getValue());
        if (fn) {
            return of(fn);
        }

        return this.http.get(`${environment.apiUrl}/setting/LABEL_URL_MAPPINGS`).pipe(
            map((data: any) => {
                const value: any[] = JSON.parse(data?.value || JSON.stringify([]));
                return value.map(({ match, pattern, replaceBy }: any) => ({
                    match,
                    fn: (url: string) => url.replace(new RegExp(pattern), replaceBy)
                })).concat(this.DEFAULT_MAPPING);
            }),
            catchError(err => {
                console.error(err);
                return of([]);
            }),
            tap((fns) => this.LABEL_MAPPINGS$.next(fns)),
            map(fns => lookupFn(fns))
        );
    }
}
