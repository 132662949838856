import { Injectable, Provider } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";
import { AuthenticationService } from '../services/authentication.service';
import { Console } from 'console';

@Injectable()
export class EZHttpInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.authenticationService.userSession;
        const isLoggedIn = user?.access_token;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    'Content-Type' : 'application/json',
                    'Access-Control-Allow-Orgin': '*',
                    Authorization: `Bearer ${user.access_token}`
                }
            });
        }

        return next.handle(request);
    }
}