import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, distinctUntilChanged, filter, map, Observable } from 'rxjs';

import { FreeTextEditorAnnotation, InkEditorAnnotation, NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import moment from 'moment';
import { AuthenticationService } from '../services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-pdf-viewer',
  standalone: true,
  imports: [
    NgxExtendedPdfViewerModule,
  ],
  templateUrl: './pdf-viewer.component.html',
  styleUrl: './pdf-viewer.component.css',
})
export class PdfViewerComponent implements OnDestroy {
  pdfReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  order: any;
  base64Src: any;
  bearerToken: string;

  constructor(private config: DynamicDialogConfig, private pdfViewerService: NgxExtendedPdfViewerService, private authService: AuthenticationService, private http: HttpClient) {
    this.bearerToken = `Bearer ${this.authService.userSession?.access_token}`;

    this.order = this.config.data.order;
    this.loadLabel(this.config.data.url).subscribe((base64) => {
      this.base64Src = base64;
    });

    console.log(this.order)

    this.pdfReady$.pipe(
      filter(value => value),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.addOrderDetail();
    });
  }

  ngOnDestroy(): void {
    document.querySelector('body > #printContainer')?.remove();
    document.querySelector('body > #fileInput')?.remove();
  }

  addOrderDetail = () => {
    const x = 8, y = 1.5, x2 = 300, y2 = 40; // magic number: coordinate in viewpoint
    const orderDetail = this.order.order_variants.reduce((acc: any, cur: any) => {
      const variant = cur.variant;

      if (!acc[variant.style]) {
        acc[variant.style] = 0;
      }

      acc[variant.style] += cur.quantity;
      return acc;
    }, {});
    
    const getValue = () => [`${moment(this.order.created_time).format('MM_DD')} - #${this.order.id}`, Object.keys(orderDetail).map(key => `${key} x ${orderDetail[key]}`).join('\t')].join('\n');
    const textEditorAnnotation: FreeTextEditorAnnotation = {
      annotationType: 3,
      fontSize: 10,
      value: getValue(),
      pageIndex: 0,
      color: [0, 0, 0],
      rect: [
        x, y, x2, y2
      ],
      rotation: 0
    };


    const inkEditorAnnotation: InkEditorAnnotation = {
      annotationType: 15,
      color: [255, 255, 255],
      thickness: 1,
      opacity: 1,
      pageIndex: 0,
      rect: [
        x, y, x2, y2
      ],
      rotation: 0,
      paths: []
    };

    const step = 1;
    for (let posY = y; posY <= y2; posY += step) {
      inkEditorAnnotation.paths.push({
        bezier: [x, posY, x, posY, x2, posY, x2, posY],
        points: [x, posY, x2, posY]
      });
    }

    this.pdfViewerService.addEditorAnnotation(inkEditorAnnotation);
    this.pdfViewerService.addEditorAnnotation(textEditorAnnotation);
  }

  onPagesLoaded(event: any) {
  }

  onEvent(type: string, event: any) {
    const actions: any = {
      'annotationEditorLayerRendered': () => this.pdfReady$.next(true)
    }
    actions[type] && actions[type]();
  }

  loadLabel(url: string): Observable<string> {
    return this.http.get(`${environment.cdnUrl}/labels/${this.order.id}.pdf`, { responseType: 'arraybuffer' }).pipe(
      map((arrayBuffer: ArrayBuffer) => {
        let binary = '';
        const bytes = new Uint8Array(arrayBuffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      }),
      catchError((err: any) => {
        if (url.includes(environment.cdnUrl)) {
          return '';
        }
        return this.loadLabel(`${environment.cdnUrl}/labels/${this.order.id}.pdf`);
      })
    );
  }
}
