import { AfterViewInit, Component, ElementRef, HostListener, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonService } from './services/common.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  imports: [
    RouterOutlet
  ],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('main', { static: true }) main: ElementRef<any> | undefined;
  title = 'PrintHubPod Fulfillment Management';

  constructor(@Inject(PLATFORM_ID) private platformId: any, private commonService: CommonService) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const { width } = this.main?.nativeElement.getBoundingClientRect();
      this.commonService.width$.next(width);
    }
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    this.commonService.width$.next(width);
  }
}
