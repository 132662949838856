import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { FieldsetModule } from 'primeng/fieldset';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [CommonModule, SharePrimeNGModule, FieldsetModule, ScrollPanelModule],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AboutUsComponent {}
