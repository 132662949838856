import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { WalletToolbarComponent } from '../../shared-ui/wallet-toolbar/wallet-toolbar.component';
import { WalletService } from '../../services/wallet.service';
import { TransactionStatus, TransactionType, WalletTransaction } from '../../models/wallet.model';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SettingService } from '../../services/setting.service';
import { Setting } from '../../models/setting.model';
import { AuthenticationService } from '../../services/authentication.service';
import { MessageService } from 'primeng/api';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { finalize } from 'rxjs/operators';

interface Filter {
  userName: string;
  maxDate: Date;
}

@Component({
  selector: 'app-my-wallet',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharePrimeNGModule, WalletToolbarComponent],
  providers: [MessageService],
  templateUrl: './my-wallet.component.html',
  styleUrl: './my-wallet.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MyWalletComponent {
  settings: Setting[] = [];
  transactions: WalletTransaction[] = [];
  totalTransactions: number = 0;
  filters: Filter = {
    userName: '',
    maxDate: new Date(),
  };
  currentPage: any = {
    page: 0,
    rows: 20,
  };
  displayDepositDialog: boolean = false;
  depositForm!: FormGroup;
  newTransaction: WalletTransaction = {
    id: 0,
    type: TransactionType.FUND,
    user_name: '',
    transfer_type: '',
    balance: 0,
    amount: 0,
    change_by: '',
    status: TransactionStatus.REQUESTED,
    note: '',
    created_time: new Date(),
    modified_time: new Date(),
  };
  transferTypes: string[] = [];
  loginUserName: any;
  isLoading = false;

  constructor(
    private walletService: WalletService,
    private settingService: SettingService,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {
    authenticationService.user.subscribe((user) => {
      this.loginUserName = user;
    });
    this.loginUserName = this.authenticationService.userSession.userName;
  }

  ngOnInit(): void {
    this.loadSettings();
    this.depositForm = this.fb.group({
      amount: ['', Validators.required],
      account: ['', Validators.required],
    });
    this.getTransactions();
  }

  getTransactions(offset: number = 0, limit: number = 20) {
    this.isLoading = true;
    const userName = this.loginUserName;

    this.walletService.findTransactions(offset, limit, userName, '', '')
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe({
      next: (response) => {
        this.transactions = response.data;
        this.totalTransactions = response.total;
      },
      error: (error) => {
        this.sendError('Load wallet transactions!', `Unable to perform action: ${error}`);
      },
    });
  }

  cancelTransaction(t: WalletTransaction): void {
    t.status = TransactionStatus.CANCELLED;
    t.modified_time = new Date();
    this.walletService.updateTransaction(t).subscribe({
      next: () => {
        this.reloadTransactions();
      },
      error: (error) => {
        this.sendError('Update transaction!', `Unable to perform action: ${error}`);
      },
      complete: () => {},
    });
  }

  onPageChange(event: any) {
    this.currentPage = event;
    this.getTransactions(event.page * event.rows, event.rows);
  }

  reloadTransactions() {
    // Reload transactions and keep current page data
    if (this.currentPage) {
      this.getTransactions(this.currentPage.page * this.currentPage.rows, this.currentPage.rows);
    } else {
      this.getTransactions();
    }
  }

  onSubmitDeposit(): void {
    this.newTransaction.user_name = this.loginUserName;
    this.newTransaction.change_by = this.loginUserName;

    this.walletService.createTransaction(this.newTransaction).subscribe({
      next: () => {
        this.reloadTransactions();
      },
      error: (error) => {
        this.sendError('Create transaction!', `Unable to perform action: ${error}`);
      },
      complete: () => {
        this.displayDepositDialog = false;
      },
    });
  }

  loadSettings() {
    this.settingService.findAllSettings().subscribe({
      next: (response) => {
        this.settings = response;
        const transferTypes = this.settings.find((s) => s.key === 'TRANSACTION_TRANSFER_TYPE')?.value;
        if (transferTypes) {
          this.transferTypes = transferTypes.split(';');
        }
      },
      error: (error) => {
        this.sendError('Load setting!', `Unable to perform action: ${error}`);
      },
      complete: () => {},
    });
  }

  sendInfo(summary: string, detail: string) {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
    });
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }
}
