import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { MessageService } from 'primeng/api';
import { AnonymousService } from '../../services/anonymous.service';

@Component({
  selector: 'app-service-catalog',
  standalone: true,
  imports: [CommonModule, SharePrimeNGModule],
  templateUrl: './service-catalog.component.html',
  styleUrl: './service-catalog.component.css',
  providers: [MessageService],
})
export class ServiceCatalogComponent {
  tableHeaders: any;
  tableData: any;

  constructor(private anonymousService: AnonymousService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.anonymousService.getPricePreview().subscribe({
      next: (response) => {
        if (response.length > 0) {
          this.tableHeaders = response[0];
          this.tableData = response.slice(1);
        } else {
          console.log(response);
        }
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Get Price Preview!',
          detail: `Error in communcating with server: ${err}`,
        });
      },
    });
  }
}
