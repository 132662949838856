<p-tabView #mainTabView [(activeIndex)]="activeIndex" [scrollable]="true" (onClose)="onTabClose($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <p-avatar icon="pi pi-list-check" styleClass="mr-2" shape="circle" />
      <span class="font-bold white-space-nowrap m-0">Dashboard</span>
    </ng-template>
    <ng-template pTemplate="content">
      <form [formGroup]="searchForm">
        <p-toolbar>
          <div class="p-toolbar-group-start"></div>
          <div class="p-toolbar-group-center">
            <input pInputText class="mr-2" formControlName="orderId" placeholder="Order ID" pTooltip="Enter Order ID to search" tooltipPosition="top" />
            <p-dropdown
              class="mr-2"
              formControlName="orderStatus"
              [options]="orderStatuses"
              optionLabel="label"
              optionValue="value"
              placeholder="Select Order Status"
              pTooltip="Select Order Status to search"
              tooltipPosition="top"
            ></p-dropdown>
            <input
              pInputText
              class="mr-2"
              formControlName="convId"
              placeholder="Conversation ID"
              pTooltip="Enter Conversation ID to search"
              tooltipPosition="top"
            />
            <p-dropdown
              class="mr-2"
              formControlName="convStatus"
              [options]="convStatuses"
              optionLabel="label"
              optionValue="value"
              placeholder="Select Conversation Status"
              pTooltip="Select Conversation Status to search"
              tooltipPosition="top"
            ></p-dropdown>
            <input
              pInputText
              class="mr-2"
              formControlName="userName"
              placeholder="User Name"
              pTooltip="Enter User Name in conversation to search"
              tooltipPosition="top"
            />
            <p-button icon="pi pi-search" label="Search" severity="success" (click)="onSearch()" class="p-button-success" class="mr-2" />
            <p-button icon="pi pi-eraser" label="Clear" severity="success" (click)="onClearSearch()" class="p-button-success" class="mr-2" />
          </div>
          <div class="p-toolbar-group-end"></div>
        </p-toolbar>
      </form>
      <p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{ height: '3px' }" />
      <div class="card-dashboard">
        <p-table [value]="dashboardConversations" [scrollable]="true" scrollHeight="flex" [paginator]="false">
          <ng-template pTemplate="header">
            <tr>
              <th>#</th>
              <th pSortableColumn="orderId" class="nowrap-header">Order ID</th>
              <th pSortableColumn="orderStatus" class="nowrap-header">Order Status</th>
              <th pSortableColumn="id" class="nowrap-header">Conversation ID</th>
              <th pSortableColumn="status" class="nowrap-header">Conversation Status <p-sortIcon field="status"></p-sortIcon></th>
              <th>Short Summary</th>
              <th>Last Comment</th>
              <th pSortableColumn="created_time" class="nowrap-header">Created Time <p-sortIcon field="created_time"></p-sortIcon></th>
              <th pSortableColumn="modified_time" class="nowrap-header">Modified Time <p-sortIcon field="modified_time"></p-sortIcon></th>
              <th style="text-align: center; width: 100px">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-i let-rowIndex="rowIndex">
            <tr class="data-row">
              <td>{{ rowIndex + 1 }}</td>
              <td>
                <a (click)="openConversation(i.orderId)" pTooltip="Open conversation" tooltipPosition="top" class="link-button">{{ i.orderId }}</a>
              </td>
              <td>
                <strong>{{ i.orderStatus | titlecase }}</strong>
              </td>
              <td>
                <a (click)="openConversation(i.orderId)" pTooltip="Open conversation" tooltipPosition="top" class="link-button">{{ i.id }}</a>
              </td>
              <td>
                <span [ngSwitch]="i.status | uppercase">
                  <p-tag *ngSwitchCase="'RESOLVED'" severity="success" icon="pi pi-check-circle" [value]="i.status"></p-tag>
                  <p-tag *ngSwitchCase="'NEW'" severity="danger" icon="pi pi-flag-fill" [value]="i.status"></p-tag>
                  <p-tag *ngSwitchDefault severity="warning" icon="pi pi-ban" [value]="i.status"></p-tag>
                </span>
              </td>
              <td>{{ i.first_comment }}</td>
              <td>{{ i.last_comment }}</td>
              <td>{{ i.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
              <td>{{ i.modified_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
              <td>
                <button
                  pButton
                  type="button"
                  pTooltip="Open conversation"
                  tooltipPosition="top"
                  icon="pi pi-list-check"
                  (click)="openConversation(i.orderId)"
                  class="p-button-rounded small-button mr-2"
                ></button>
                <button
                  pButton
                  *ngIf="(i.status | uppercase) !== 'RESOLVED'"
                  icon="pi pi-check"
                  pTooltip="Resolve conversation"
                  tooltipPosition="top"
                  (click)="resolveConversation(i)"
                  class="small-button p-button-rounded p-button-success"
                  style="background: green"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <p-paginator
        #paginator
        [rows]="defaultPageLimit"
        [totalRecords]="totalConversations"
        [rowsPerPageOptions]="[defaultPageLimit, 50, 100]"
        [showFirstLastIcon]="true"
        (onPageChange)="onPageChangeDashboard($event)"
        currentPageReportTemplate="Showing {first} to {last} of {{ totalConversations }}"
        [showCurrentPageReport]="true"
      ></p-paginator>
    </ng-template>
  </p-tabPanel>

  <p-tabPanel *ngFor="let con of conversations" [closable]="true">
    <ng-template pTemplate="header">
      <p-avatar icon="pi pi-comment" styleClass="mr-2" shape="circle" />
      <span class="font-bold white-space-nowrap m-0"># {{ con.id }}</span>
    </ng-template>
    <ng-template pTemplate="content">
      <p-toolbar>
        <div class="p-toolbar-group-start">
          <p-button
            icon="pi pi-check-circle"
            label="Resolve"
            severity="success"
            (click)="resolveConversation(con)"
            *ngIf="(con.status | uppercase) !== 'RESOLVED'"
            class="p-button-success"
            class="mr-2"
          />
          <p-button icon="pi pi-times-circle" label="Close" severity="secondary" (click)="onCloseThisTab(con)" />
        </div>
        <div class="p-toolbar-group-center">
          <p-button icon="pi pi-check" [rounded]="true" [text]="true" severity="info" /><strong class="mr-2">Order ID:</strong> {{ con.orderId }}
          <p-button icon="pi pi-check" [rounded]="true" [text]="true" severity="info" class="ml-4" /><strong class="mr-2">Conversation #:</strong> {{ con.id }}
          <p-button icon="pi pi-check" [rounded]="true" [text]="true" severity="info" class="ml-4" /><strong>Status:</strong>
          <p-button icon="pi pi-bell" [text]="true" label="{{ con.status }}" severity="success" class="mr-2" />
        </div>
        <div class="p-toolbar-group-end">
          <strong class="mr-2">Created Time: </strong> {{ con.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}
          <strong class="ml-4 mr-2">Modified Time: </strong> {{ con.modified_time | date : "MMM dd, yyyy hh:mm:ss a" }}
        </div>
      </p-toolbar>
      <p-card>
        <div class="card">
          <p-table [value]="con.comments" [paginator]="false" [scrollable]="true" scrollHeight="flex" styleClass="p-datatable-striped">
            <ng-template pTemplate="body" let-i>
              <tr class="data-row">
                <p-avatar icon="pi pi-comment" styleClass="mr-2" shape="circle" severity="danger" />
                <strong>
                  {{ i.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}
                  -
                  {{ i.created_by }}
                </strong>
                <p-panel>
                  <div [innerHTML]="i.comment"></div>
                </p-panel>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="mb-3">
          <p-editor [(ngModel)]="con.editorContent" [style]="{ height: '150px' }" />
        </div>
        <p-button icon="pi pi-send" label="Send" severity="success" class="p-button-success" (click)="sendNewConversationComment(con)" class="mr-2" />
      </p-card>
    </ng-template>
  </p-tabPanel>
</p-tabView>
<p-toast></p-toast>
