import { Component } from '@angular/core';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ContactUsService } from './contact-us.service';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [SharePrimeNGModule,ReactiveFormsModule, ToastModule],
  providers: [MessageService],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.css'
})
export class ContactUsComponent {
  constructor(private fb: FormBuilder, 
    private contactUsService : ContactUsService,
    private messageService: MessageService){
    
  }
  contactForm = this.fb.group(
    {
      fullName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/)]],
      subject: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/)]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
    }
  );

  get fullName() {
    return this.contactForm.controls['fullName'];
  }

  get subject() {
    return this.contactForm.controls['subject'];
  }

  get message() {
    return this.contactForm.controls['message'];
  }

  get email() {
    return this.contactForm.controls['email'];
  }

  submitDetails(){
    const contactUs = this.contactForm.value;
    this.contactUsService.submit(contactUs).subscribe({
      next: (res) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Thank you for reaching out! We’ve received your message and will get back to you as soon as possible',
          sticky: true,
        });
      },
      error: (e) => {
        console.log(e);
        const message = e.error.message;
        this.messageService.add({
          severity: 'error',
          summary: 'Error!',
          detail: message,
          sticky: true,
        });
      },
      complete: () => {},
    });
  }

}
