<div class="relative overflow-hidden flex flex-column justify-content-center">
  <div class="absolute top-0 bg-shape-top bg-no-repeat bg-cover w-full"></div>
  <div id="home" class="landing-wrapper z-1">
    <div class="px-4 md:px-8 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
      <div class="flex gap-2 align-items-center text-white mr-0 lg:mr-6 select-none">
        <img src="../../../assets/images/headers/logo.png" class="w-2rem h-2rem" />
        <span class="font-bold text-3xl">PrintHubPod</span>
      </div>
      <a pripple class="p-element cursor-pointer block lg:hidden text-white" (click)="isShowMenu = !isShowMenu">
        <i class="pi pi-bars text-4xl"></i>
      </a>
      <div
        id="menu"
        class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-3 shadow-2 lg:shadow-none md:bg-transparent"
        [class]="{ hidden: !isShowMenu }"
      >
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
          <li *ngFor="let menuItem of topNavMenu">
            <a
              routerLink="{{ menuItem.url }}"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="active"
              [ngClass]="{ 'nav-link': true }"
              (click)="onMenuItemClick()"
            >
              {{ menuItem.label }}
            </a>
          </li>
        </ul>
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
          <li *ngFor="let menuItem of topLeftNavMenu; let last = last">
            <a
              [routerLink]="menuItem.url"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="active"
              [ngClass]="{ 'nav-link': true }"
              (click)="onMenuItemClick()"
            >
              {{ menuItem.label }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="front-content px-4 md:px-8 z-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
