import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '../models/store.model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private apiUrl = `${environment.apiUrl}/store`;

  constructor(private http: HttpClient) {}

  getAllStores(): Observable<Store[]> {
    return this.http.get<Store[]>(`${this.apiUrl}/get-all-store`);
  }

  createNewStore(store: Store): Observable<Store> {
    return this.http.post<Store>(`${this.apiUrl}/add-new-store`, store);
  }

  deleteStore(storeId: any): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/delete-store/${storeId}`);
  }

  updateStoreTier(store: Store): Observable<Store> {
    return this.http.post<Store>(`${this.apiUrl}/update-store-tier`, store);
  }

  updateStoreDetail(store: Store): Observable<Store> {
    return this.http.post<Store>(`${this.apiUrl}/update-store-detail`, store);
  }

  updateStoreUsers(store: Store): Observable<Store> {
    return this.http.post<Store>(`${this.apiUrl}/update-store-users`, store);
  }
}
