import { AfterContentInit, Component, EventEmitter, Inject, Input, OnChanges, Output, PLATFORM_ID, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';

import { SharePrimeNGModule } from '../../../share-primeng.module';

@Component({
  selector: 'app-line-charts',
  standalone: true,
  imports: [
    CommonModule,
    SharePrimeNGModule
  ],
  templateUrl: './line-charts.component.html',
  styleUrl: './line-charts.component.css'
})
export class LineChartsComponent implements OnChanges {
  @ViewChild('chart') chart: any;
  @ViewChild('line1', { static: true }) line1: TemplateRef<any>;
  @ViewChild('line2', { static: true }) line2: TemplateRef<any>;
  @ViewChild('line3', { static: true }) line3: TemplateRef<any>;
  @ViewChild('line4', { static: true }) line4: TemplateRef<any>;

  @Input()
  dataPoints: any[];

  @Input()
  status: 'new' | 'in-progress' | 'out-for-delivery' | 'completed' | any = 'new';


  @Output()
  onSelectChart: EventEmitter<string> = new EventEmitter();

  data: { datasets: any[] } = {
    datasets: []
  };
  options = {};

  @Input()
  chartConfigs: any[];

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (isPlatformBrowser(this.platformId)) {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');

      this.chartConfigs = this.chartConfigs.map((config, i) => ({
        ...config,
        fill: true,
        tension: 0.45,
        borderColor: documentStyle.getPropertyValue(`--line-charts-color-${i + 1}`),
        backgroundColor: documentStyle.getPropertyValue(`--line-charts-background-${i + 1}`),
        line: (this as any)[`line${i + 1}`],
      }));

      this.data = {
        datasets: [{
          ...this.chartConfigs.find(({ status }: any) => status === this.status),
          data: this.dataPoints
        }]
      };

      this.options = {
        maintainAspectRatio: false,
        aspectRatio: 0.57,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary
            },
          },
          y: {
            display: false,
          }
        }
      };
      this.chart?.refresh();
    }
  }

  toggleChart(status: string) {
    this.onSelectChart.emit(status);
  }
}
