import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductToolbarComponent } from '../shared-ui/product-toolbar/product-toolbar.component';
import { ProductService } from '../services/product.service';
import { Product } from '../models/product.model';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SharePrimeNGModule } from '../share-primeng.module';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [CommonModule, SharePrimeNGModule, ProductToolbarComponent],
  templateUrl: './products.component.html',
  styleUrl: './products.component.css',
  providers: [ConfirmationService, MessageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProductsComponent {
  products: Product[] = [];
  isLoading = false;

  constructor(
    private productService: ProductService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.productService.findAll()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe({
      next: (response) => {
        this.products = response;
      },
      error: (error) => {
        this.sendError('Load product list!', `'Error loading products: ${error}`);
      },
    });

    this.productService.syncVariants().subscribe();
  }

  openDeleteDialog(product: Product): void {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this product?<br><strong>${product.name}</strong>`,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteProduct(product);
      },
      reject: () => {},
    });
  }

  openProductEdit(product: Product) {
    this.router.navigate([`/product/edit-product/${product.id}`]);
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }

  deleteProduct(product: Product) {
    // Delete product on local
    const index = this.products.findIndex((p) => p.id === product.id);
    if (index > -1) {
      this.products.splice(index, 1);

      // Delete product on server
      this.productService.deleteProduct(product).subscribe({
        next: () => {},
        error: (error) => {
          this.sendError('Delete product!', `'Error delete product: ${error}`);
        },
      });
    }
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }
}
