import { CommonModule } from '@angular/common';
import { Component, TemplateRef, ViewChild, AfterContentInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-cards',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './cards.component.html',
  styleUrl: './cards.component.css'
})
export class CardsComponent implements OnChanges {
  @ViewChild('line1', { static: true }) line1: TemplateRef<any> | undefined;
  @ViewChild('line2', { static: true }) line2: TemplateRef<any> | undefined;
  @ViewChild('line3', { static: true }) line3: TemplateRef<any> | undefined;

  @Input()
  overallStatistics: any[] = [];

  @Input()
  cards: any[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.overallStatistics = this.overallStatistics.map((stat, idx: number) => ({
      ...stat,
      line: (this as any)[`line${idx + 1}`]
    }))
  }
}
