import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ProductToolbarComponent } from '../../shared-ui/product-toolbar/product-toolbar.component';
import { BatchUpdateVariant, EditableVariant, Product, ProductSize, Variant, VariantQuery } from '../../models/product.model';
import { ProductService } from '../../services/product.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { ConfirmationService, MessageService } from 'primeng/api';
import { combineLatest, finalize } from 'rxjs';
import { SettingService } from '../../services/setting.service';
import { response } from 'express';

@Component({
  selector: 'app-variants',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharePrimeNGModule, ProductToolbarComponent],
  providers: [ConfirmationService, MessageService],
  templateUrl: './variants.component.html',
  styleUrl: './variants.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VariantsComponent {
  showBatchUpdateDialog = false;
  optionProducts: Product[] = [];
  selectedProduct: Product | undefined;
  optionPricingTiers: any[] = [];
  selectedPricingTier: any;
  defaultTier: string = 'standard';
  optionStyles: any[] = [];
  optionSizes: any[] = [];
  optionColors: any[] = [];
  selectedSearch: any = {
    style: '',
    size: '',
    color: '',
  };
  sizes: ProductSize[] = [];
  selectedSizes: string[] = [];

  batchUpdateVariant: BatchUpdateVariant = { sizes: [] };

  variants: EditableVariant[] = [];
  variantsOriginal: EditableVariant[] = [];
  selectedVariants: EditableVariant[] = [];
  linkVariantDesign: any;

  isLoading = false;

  compareStrings = (a: any, b: any) => a.localeCompare(b, undefined, { sensitivity: 'base' });

  constructor(private productService: ProductService, private confirmationService: ConfirmationService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.selectedVariants = [];
    this.getPriceTiers();
    this.getSheetID();
  }

  getSheetID() {
    this.productService.getDefaultVariantDesignSheetID().subscribe({
      next: (response) => {
        this.linkVariantDesign = response.sheetId;
      },
      error: (err) => {
        this.showError('Error!', `Error getting google sheet id: ${err}`);
      },
    });
  }

  getVariants(
    query: VariantQuery = {
      price_tier: this.selectedPricingTier?.name || this.defaultTier,
    }
  ) {
    this.isLoading = true;
    this.productService
      .findVariants(query)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.variants = response.map((variant) => ({
            ...variant,
            isEditMode: false, // Default value when missing from server
          }));

          this.variants.sort((a, b) => {
            let result = this.compareStrings(a.style, b.style);
            if (result !== 0) return result;

            result = this.compareStrings(a.size, b.size);
            if (result !== 0) return result;

            return this.compareStrings(a.color, b.color);
          });

          this.optionStyles = [...new Set(this.variants.map((variant) => variant.style))];
          this.optionSizes = [...new Set(this.variants.map((variant) => variant.size))];
          this.optionColors = [...new Set(this.variants.map((variant) => variant.color))];

          this.variantsOriginal = this.variants;
        },
        error: (err) => {
          this.showError('Error!', `Error loading variants: ${err}`);
        },
      });
  }

  getProducts() {
    this.productService.findAll().subscribe({
      next: (response) => {
        this.optionProducts = response;
      },
      error: (err) => {
        this.showError('Error!', `Error loading products: ${err}`);
      },
    });
  }

  getPriceTiers() {
    this.isLoading = true;
    this.productService
      .findPricingTiers()
      .subscribe({
        next: (response) => {
          this.optionPricingTiers = response;
          this.selectedPricingTier = this.optionPricingTiers.find((tier) => tier.name?.toLowerCase() === this.defaultTier) || null;
          this.getVariants(); // Get variant of selected tier
        },
        error: (err) => {
          this.showError('Error!', `Error loading pricing tiers: ${err}`);
          this.isLoading = false;
        },
      });
  }

  onSearch(): void {
    this.variants = this.variantsOriginal.filter((variant) => {
      return (
        (!this.selectedSearch.style || variant.style === this.selectedSearch.style) &&
        (!this.selectedSearch.size || variant.size === this.selectedSearch.size) &&
        (!this.selectedSearch.color || variant.color === this.selectedSearch.color)
      );
    });
  }

  onShowBathUpdateDialog() {
    this.getProducts();
    this.showBatchUpdateDialog = true;

    this.selectedSizes = [];
    this.batchUpdateVariant = { sizes: [] };
  }

  openGoogleSpreadSheet() {
    window.open(`https://docs.google.com/spreadsheets/d/${this.linkVariantDesign}`, '_blank');
  }

  onProductChange(event: any) {
    this.selectedProduct = event.value;
    this.sizes = this.selectedProduct ? this.selectedProduct.sizes : [];
  }

  onProductSizeChange(event: any) {
    const selectedSizes = event.value;
    this.batchUpdateVariant.sizes = selectedSizes;
    this.batchUpdateVariant.tierId = this.selectedPricingTier?.id;

    if (this.selectedProduct && selectedSizes.length > 0) {
      const foundVariant: EditableVariant | undefined = this.variants.find(
        (v) => v.style === this.selectedProduct?.style && v.brand === this.selectedProduct?.brand && v.size === selectedSizes[0]
      );

      if (foundVariant) {
        this.batchUpdateVariant.weight = foundVariant.weight;
        this.batchUpdateVariant.length = foundVariant.length;
        this.batchUpdateVariant.width = foundVariant.width;
        this.batchUpdateVariant.height = foundVariant.height;

        if (foundVariant.prices && foundVariant.prices.length > 0) {
          const firstPrice = foundVariant.prices[0];
          this.batchUpdateVariant.basecost = firstPrice.basecost;
          this.batchUpdateVariant.price_2nd_side = firstPrice.price_2nd_side;
          this.batchUpdateVariant.price_sleeve = firstPrice.price_sleeve;
          this.batchUpdateVariant.price_shipping = firstPrice.price_shipping;
          this.batchUpdateVariant.price_shipping_2nd_item = firstPrice.price_shipping_2nd_item;
        }
      }
    }
  }

  onBatchUpdate() {
    if (!this.selectedProduct || this.batchUpdateVariant.sizes?.length === 0) {
      this.showError('Error!', 'Please select a product and size to update associated variants');

      return;
    }

    this.confirmationService.confirm({
      message: `Are you sure you want to apply changes to multiple variants?`,
      header: 'Change Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.batchUpdateVariant.productId = this.selectedProduct?.id;
        this.productService.batchUpdateVariants(this.batchUpdateVariant).subscribe({
          next: (data) => {
            this.showBatchUpdateDialog = false;
            this.showSuccess('Update Variants successfully!', `${data} variants have been updated successfully`);

            this.getVariants();
          },
          error: (err) => {
            this.showError(err.error, `Error updating variant: ${err.message}`);
          },
          complete: () => {},
        });
      },
      reject: () => {},
    });
  }

  editVariant(variant: EditableVariant): void {
    variant.isEditMode = true;
    const index = this.selectedVariants.findIndex((v) => v.id === variant.id);
    if (index < 0) {
      this.selectedVariants.push({ ...variant }); // Copy original variant locally
    } else {
      // Update selected one
      this.selectedVariants[index] = { ...this.selectedVariants[index], ...variant };
    }
  }

  saveVariant(variant: EditableVariant): void {
    variant.isEditMode = false;
    this.productService.updateVariant(variant).subscribe({
      next: () => {},
      error: (err) => {
        this.showError('Error!', `Error updating variant: ${err.messages || err}`);
      },
      complete: () => {
        this.showSuccess('Update Variants successfully!', `The variant have been updated successfully`);
      },
    });
  }

  cancelEditVariant(variant: EditableVariant): void {
    const index1 = this.selectedVariants.findIndex((v) => v.id === variant.id);
    const index2 = this.variants.findIndex((v) => v.id === variant.id);
    this.selectedVariants[index1].isEditMode = false;
    this.variants[index2] = { ...this.variants[index2], ...this.selectedVariants[index1] };
  }

  onClearSearch() {
    this.selectedSearch = { style: '', size: '', color: '' };
    this.variants = [...this.variantsOriginal];
  }

  onChangePricingTier() {
    this.selectedSearch = { style: '', size: '', color: '' };
    this.getVariants({ price_tier: this.selectedPricingTier?.name });
  }

  private showError(summary: string, detail: string): void {
    this.messageService.add({ severity: 'error', summary, detail, life: 10000 });
  }

  private showSuccess(summary: string, detail: string): void {
    this.messageService.add({ severity: 'success', summary, detail, life: 10000 });
  }
}
