import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class UserProfileService {
    private apiUrl = `${environment.apiUrl}`;
    constructor(private http: HttpClient){

    }

    getUserProfile(id: number){
        const url = `${this.apiUrl}/user-profile/get-profile/${id}`;
        return this.http.get(url);
    }

    saveUserProfile(data: any){
        const url = `${this.apiUrl}/user-profile/update-profile`;
        return this.http.put(url, data)
    }

    generateApiKey(){
        const url = `${this.apiUrl}/user-profile/key`;
        return this.http.get(url)
    }
}