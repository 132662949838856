<app-user-management-toolbar></app-user-management-toolbar>
<br />
<p-toolbar>
  <p-button icon="pi pi-plus" label="Create Role" severity="success" class="p-button-success" (click)="openDialog()" />
</p-toolbar>
<p-card>
  <p-table [value]="roles" class="p-datatable-sm">
    <ng-template pTemplate="header">
      <tr>
        <th>Role Name</th>
        <th>Display Name</th>
        <th>Description</th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-role let-rowIndex="rowIndex">
      <tr class="data-row">
        <td>{{ role.name }}</td>
        <td>{{ role.displayName }}</td>
        <td>{{ role.description }}</td>
        <td>
          <button
            *ngIf="!['Admin', 'Seller'].includes(role.name)"
            pButton
            type="button"
            icon="pi pi-pencil"
            pTooltip="Update"
            tooltipPosition="top"
            class="p-button-rounded p-button-success p-button-text"
            (click)="openRoleEdit(role)"
          ></button>
          <button
            *ngIf="!['Admin', 'Seller'].includes(role.name)"
            pButton
            type="button"
            icon="pi pi-trash"
            pTooltip="Delete"
            tooltipPosition="top"
            severity="danger"
            [outlined]="true"
            class="p-button-rounded p-button-danger p-button-text"
            (click)="openDeleteDialog(role)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-confirmDialog [style]="{ width: '30vw' }"></p-confirmDialog>
</p-card>
<p-dialog header="Role" [(visible)]="displayDialog" [modal]="true" [style]="{ width: '30rem' }">
  <form [formGroup]="roleForm">
    <div class="grid">
      <input formControlName="id" hidden />
      <div class="col-12">
        <div class="field" style="display: grid">
          <label for="roleName" class="font-semibold w-8rem">Role Name</label>
          <input pInputText id="roleName" formControlName="roleName" class="flex-auto" autocomplete="off" />
          <small *ngIf="roleForm.get('roleName')?.invalid && (roleForm.get('roleName')?.dirty || roleForm.get('roleName')?.touched)" class="block p-error">
            <div *ngIf="roleForm.get('roleName')?.errors?.['required']">Name is required.</div>
          </small>
        </div>
      </div>
      <div class="col-12">
        <div class="field" style="display: grid">
          <label for="displayName" class="font-semibold w-8rem">Display Name</label>
          <input pInputText id="displayName" formControlName="displayName" class="flex-auto" autocomplete="off" />
          <small
            *ngIf="roleForm.get('displayName')?.invalid && (roleForm.get('displayName')?.dirty || roleForm.get('displayName')?.touched)"
            class="block p-error"
          >
            <div *ngIf="roleForm.get('displayName')?.errors?.['required']">Display Name is required.</div>
          </small>
        </div>
      </div>
      <div class="col-12">
        <div class="field" style="display: grid">
          <label for="description" class="font-semibold w-8rem">Description</label>
          <input pInputText id="description" formControlName="description" class="flex-auto" autocomplete="off" />
        </div>
      </div>
      <div class="col-12">
        <p-button
          icon="pi pi-save"
          [ngStyle]="{ 'margin-right': '8px' }"
          label="Save"
          severity="success"
          [disabled]="roleForm.invalid"
          class="p-button-success"
          (click)="save()"
        />
        <p-button icon="pi pi-times" label="Cancel" severity="success" class="p-button-success" (click)="closeDialog()" />
      </div>
    </div>
  </form>
</p-dialog>
<p-toast position="top-center"></p-toast>
