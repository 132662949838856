import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SharePrimeNGModule } from '../share-primeng.module';
import { ProductToolbarComponent } from '../shared-ui/product-toolbar/product-toolbar.component';
import { Store, StoreUser, User } from '../models/store.model';
import { StoreService } from '../services/store.service';
import { ProductService } from '../services/product.service';
import { UserManagementService } from '../users-management/user.management.service';

@Component({
  selector: 'app-stores',
  standalone: true,
  imports: [CommonModule, SharePrimeNGModule, ProductToolbarComponent],
  providers: [ConfirmationService, MessageService],
  templateUrl: './stores.component.html',
  styleUrl: './stores.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StoresComponent {
  showNewStoreDialog = false;
  showUpdatePriceTierDialog = false;
  showUpdateStoreDialog = false;
  showUpdateStoreUsersDialog = false;

  newStore: Store = {};
  updateStore: Store = {};
  updateStoreTier: Store = {};
  updateStoreUser: Store = {};
  stores: Store[] = [];
  pricingTiers: any[] = [];

  allUsers: User[] = [];
  selectedUsers: User[] = [];
  availableUsers: User[] = [];
  hlSelectedIds: number[] = [];
  hlAvailableIds: number[] = [];

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private storeService: StoreService,
    private productService: ProductService,
    private userService: UserManagementService
  ) {}

  ngOnInit(): void {
    this.getStores();
    this.getPriceTiers();
    this.getUsers();
  }

  getPriceTiers() {
    this.productService.findPricingTiers().subscribe({
      next: (response) => {
        this.pricingTiers = response;
      },
      error: (err) => {
        this.sendError('Error!', `Error loading pricing tiers: ${err}`);
      },
    });
  }

  getUsers() {
    this.userService.getUsers(1, 0, '').subscribe({
      next: (response: any) => {
        this.allUsers = response.users;
      },
      error: (error) => {
        this.sendError('Error!', `Failed to get user list: ${error.messages ? error.messages : error}`);
      },
    });
  }

  get selectedTierId(): number | null {
    return this.updateStoreTier.tier?.id || null;
  }

  set selectedTierId(value: number | null) {
    if (this.updateStoreTier.tier) {
      this.updateStoreTier.tier.id = value;
    } else {
      this.updateStoreTier.tier = { id: value };
    }
  }

  saveNewStore() {
    if (!this.newStore.name || !this.newStore.description) {
      this.sendError('Error!', `Please provide the team name and description before saving.`);
      return;
    }

    this.storeService.createNewStore(this.newStore).subscribe({
      next: () => {
        this.showNewStoreDialog = false;
        this.getStores();
        this.sendInfo('New Team is created!', `The Team ${this.newStore.name} has been added successfully.`);

        this.newStore = {};
      },
      error: (err) => {
        this.sendError(err.error, `Error creating team: ${err.messages}`);
      },
    });
  }

  getStores() {
    this.storeService.getAllStores().subscribe({
      next: (response) => {
        this.stores = response;
      },
      error: (err) => {
        this.sendError('Error!', `Error loading teams: ${err}`);
      },
    });
  }

  deleteStore(store: any) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete the price tier <strong>${store.name}</strong>?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.storeService.deleteStore(store.id).subscribe({
          next: () => {
            this.getStores();
            this.sendInfo('Store is deleted!', `The Team ${store.name} has been deleted successfully.`);
          },
          error: (err) => {
            this.sendError(err.error, `Error deleting team: ${err.messages}`);
          },
        });
      },
      reject: () => {},
    });
  }

  openUpdateStoreTierDialog(store: Store) {
    this.showUpdatePriceTierDialog = true;
    this.updateStoreTier.id = store.id;
    this.updateStoreTier.name = store.name;
    this.updateStoreTier.description = store.description;
    this.updateStoreTier.tier = { id: store.tier?.id };
  }

  saveStoreTier() {
    if (!this.updateStoreTier.id) {
      this.sendError('Error!', `Please provide the team name and description before saving.`);
      return;
    }

    this.storeService.updateStoreTier(this.updateStoreTier).subscribe({
      next: () => {
        this.showUpdatePriceTierDialog = false;
        this.getStores();
        this.sendInfo('Team Tier is updated!', `The Team ${this.updateStoreTier.name}'s tier has been updated successfully.`);
      },
      error: (err) => {
        this.sendError(err.error, `Error updating team's tier: ${err.messages}`);
      },
    });
  }

  openUpdateStoreDetailDialog(store: Store) {
    this.showUpdateStoreDialog = true;
    this.updateStore.id = store.id;
    this.updateStore.name = store.name;
    this.updateStore.description = store.description;
  }

  saveStoreDetail() {
    if (!this.updateStore.name || !this.updateStore.description) {
      this.sendError('Error!', `Please provide the team name and description before saving.`);
      return;
    }

    this.storeService.updateStoreDetail(this.updateStore).subscribe({
      next: () => {
        this.showUpdateStoreDialog = false;
        this.getStores();
        this.sendInfo('Team Tier is updated!', `The Team ${this.updateStoreTier.name}'s tier has been updated successfully.`);
      },
      error: (err) => {
        this.sendError(err.error, `Error updating team: ${err.messages}`);
      },
    });
  }

  sendInfo(summary: string, detail: string) {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
    });
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }

  openUpdateStoreUsersDialog(store: Store) {
    this.showUpdateStoreUsersDialog = true;
    this.updateStoreUser.id = store.id;
    this.updateStoreUser.name = store.name;

    const ids: (number | undefined)[] = store.storeUsers?.map((us: StoreUser) => us.userId) || [];
    this.selectedUsers = this.allUsers.filter((u) => ids.includes(u.id));
    this.availableUsers = this.allUsers.filter((u) => !ids.includes(u.id));
  }

  saveStoreUsers() {
    this.updateStoreUser.storeUsers = [];
    this.selectedUsers.map((u: User) => this.updateStoreUser.storeUsers?.push({ userId: u.id }));

    this.storeService.updateStoreUsers(this.updateStoreUser).subscribe({
      next: () => {
        this.showUpdateStoreUsersDialog = false;
        this.getStores();
        this.sendInfo(`Team's Users is updated!`, `The Team ${this.updateStoreUser.name}'s users have been updated successfully.`);
      },
      error: (err) => {
        this.sendError(err.error, `Error updating team's users: ${err.messages}`);
      },
    });
  }

  addUserToStore() {
    const idsSet = new Set(this.hlAvailableIds);
    const usersToMove = this.availableUsers.filter((user) => idsSet.has(user.id));
    this.availableUsers = this.availableUsers.filter((user) => !idsSet.has(user.id));
    this.selectedUsers = [...this.selectedUsers, ...usersToMove];

    this.hlAvailableIds = [];
    this.hlSelectedIds = [];
  }

  removeUserFromStore() {
    const idsSet = new Set(this.hlSelectedIds);
    const usersToMove = this.selectedUsers.filter((user) => idsSet.has(user.id));
    this.selectedUsers = this.selectedUsers.filter((user) => !idsSet.has(user.id));
    this.availableUsers = [...this.availableUsers, ...usersToMove];

    this.hlAvailableIds = [];
    this.hlSelectedIds = [];
  }
}
