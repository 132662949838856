<p-timeline [value]="timelines" class="w-full md:w-20rem">
    <ng-template pTemplate="content" let-event>
        <small class="p-text-secondary">{{ event.date }}</small>
        <br />
        Changed by {{event.user}}
        <br />
        @if (event.status === 'OnHold') {
        <small>Pending payment</small>
        }
        @if (event.status === 'Printed') {
        <small>Payment successfully</small>
        }
    </ng-template>
    <ng-template pTemplate="opposite" let-event>
        {{ event.status }}
    </ng-template>
</p-timeline>