import { NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';
import { MeterGroupModule } from 'primeng/metergroup';
import { ChartModule } from 'primeng/chart';
import { ScrollTopModule } from 'primeng/scrolltop';
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { TableModule } from 'primeng/table';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { GalleriaModule } from 'primeng/galleria';
import { SkeletonModule } from 'primeng/skeleton';
import { TagModule } from 'primeng/tag';
import { ImageModule } from 'primeng/image';
import { PaginatorModule } from 'primeng/paginator';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogModule } from 'primeng/dialog';
import { PasswordModule } from 'primeng/password';
import { InputNumberModule } from 'primeng/inputnumber';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { TimelineModule } from 'primeng/timeline';
import { TreeTableModule } from 'primeng/treetable';
import { InplaceModule } from 'primeng/inplace';
@NgModule({
  exports: [
    ButtonModule,
    SidebarModule,
    MenuModule,
    RippleModule,
    BadgeModule,
    AvatarModule,
    CardModule,
    MeterGroupModule,
    ChartModule,
    ScrollTopModule,
    ToolbarModule,
    InputTextModule,
    CalendarModule,
    InputGroupModule,
    InputGroupAddonModule,
    TableModule,
    FloatLabelModule,
    DropdownModule,
    AccordionModule,
    CheckboxModule,
    DialogModule,
    DynamicDialogModule,
    GalleriaModule,
    SkeletonModule,
    TagModule,
    ImageModule,
    PaginatorModule,
    ProgressBarModule,
    ToastModule,
    TagModule,
    TabViewModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    RadioButtonModule,
    OverlayPanelModule,
    IconFieldModule,
    InputIconModule,
    PasswordModule,
    DialogModule,
    InputNumberModule,
    ListboxModule,
    MultiSelectModule,
    TimelineModule,
    TreeTableModule,
    InplaceModule
  ],
})
export class SharePrimeNGModule {}
