<form [formGroup]="registerForm" class="justify-content-center" style="display: flex" (ngSubmit)="submitDetails()" *ngIf="showForm">
  <p-card header="Create new account" class="justify-content-center" [style]="{ 'max-width': '800px' }">
    <div class="grid">
      <div class="col">
        <div class="field">
          <label for="firstName">First Name</label>
          <input
            type="text"
            class="w-full h-3rem border-round-md border-1 border-primary-400"
            pInputText
            formControlName="firstName"
            placeholder="Type your first name"
          />
          <small *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="block p-error" style="margin-left: 3px !important">
            <div *ngIf="firstName.errors?.['required']">First name is required.</div>
            <div *ngIf="firstName.errors?.['pattern']">Please enter a valid first name.</div>
          </small>
        </div>
        <div class="field">
          <label for="lastName">Last Name</label>
          <input
            type="text"
            class="w-full h-3rem border-round-md border-1 border-primary-400"
            pInputText
            formControlName="lastName"
            placeholder="Type your last name"
          />
          <small *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="block p-error" style="margin-left: 3px !important">
            <div *ngIf="lastName.errors?.['required']">Last name is required.</div>
            <div *ngIf="lastName.errors?.['pattern']">Please enter a valid last name.</div>
          </small>
        </div>
        <div class="field">
          <label for="email">Email</label>
          <input
            type="text"
            pInputText
            formControlName="email"
            class="w-full h-3rem border-round-md border-1 border-primary-400"
            name="email"
            placeholder="Type your email"
          />
          <small *ngIf="email.invalid && (email.dirty || email.touched)" class="block p-error" style="margin-left: 3px !important">
            <div *ngIf="email.errors?.['required']">Email address is required.</div>
            <div *ngIf="email.errors?.['email']">Please enter a valid email address.</div>
          </small>
        </div>
        <div class="field">
          <label for="userName">User Name</label>
          <input
            type="text"
            pInputText
            formControlName="userName"
            class="w-full h-3rem border-round-md border-1 border-primary-400"
            name="userName"
            placeholder="Type your user name"
          />
          <small *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="block p-error" style="margin-left: 3px !important">
            <div *ngIf="userName.errors?.['required']">User name is required.</div>
            <div *ngIf="userName.errors?.['invalidUserName']">User name should be valid.</div>
          </small>
        </div>
      </div>
      <div class="col">
        <div class="field">
          <label for="password" class="right-title">Password</label>
          <p-password [toggleMask]="true" formControlName="password" placeholder="Type your password" promptLabel="Type your password" [feedback]="false" />
        </div>
        <div class="field" style="width: 320px">
          <label for="confirmPassword" class="right-title">Confirm Password</label>
          <p-password [toggleMask]="true" formControlName="confirmPassword" promptLabel="Confirm Password" placeholder="Confirm Password" [feedback]="false" />
          <small *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)" class="block p-error">
            <div *ngIf="confirmPassword.errors?.['required']">Please confirm your password.</div>
          </small>
          <small class="block p-error" *ngIf="registerForm.errors?.['passwordMismatch'] && confirmPassword.valid && password.valid">
            The passwords do not match.
          </small>
        </div>
        <div class="field">
          <small *ngIf="password.invalid && (password.dirty || password.touched)" class="block p-error">
            <div *ngIf="password.errors?.['required']">Password is required.</div>
          </small>
          <div style="display: flex">
            <div [ngClass]="[ 'circle', password.valid || !password.errors?.['minLength'] ? 'green-circle' : 'red-circle' ]">
              <i [ngClass]="[ 'pi', password.valid || !password.errors?.['minLength']  ? 'pi-check' : 'pi-times' ]"></i>
            </div>
            <span class="label">Password must be at least 8 characters long.</span>
          </div>

          <div style="display: flex">
            <div [ngClass]="[ 'circle', password.valid || !password.errors?.['noUpperCase'] ? 'green-circle' : 'red-circle' ]">
              <i [ngClass]="[ 'pi', password.valid || !password.errors?.['noUpperCase'] ? 'pi-check' : 'pi-times' ]"></i>
            </div>
            <span class="label">Password must contain at least one uppercase letter.</span>
          </div>

          <div style="display: flex">
            <div [ngClass]="[ 'circle', password.valid || !password.errors?.['noLowerCase'] ? 'green-circle' : 'red-circle' ]">
              <i [ngClass]="[ 'pi', password.valid || !password.errors?.['noLowerCase'] ? 'pi-check' : 'pi-times' ]"></i>
            </div>
            <span class="label">Password must contain at least one lowercase letter.</span>
          </div>

          <div style="display: flex">
            <div [ngClass]="[ 'circle', password.valid || !password.errors?.['noDigit'] ? 'green-circle' : 'red-circle' ]">
              <i [ngClass]="[ 'pi', password.valid || !password.errors?.['noDigit'] ? 'pi-check' : 'pi-times' ]"></i>
            </div>
            <span class="label">Password must contain at least one number.</span>
          </div>

          <div style="display: flex">
            <div [ngClass]="[ 'circle', password.valid || !password.errors?.['noSpecialChar'] ? 'green-circle' : 'red-circle' ]">
              <i [ngClass]="[ 'pi', password.valid || !password.errors?.['noSpecialChar'] ? 'pi-check' : 'pi-times' ]"></i>
            </div>
            <span class="label">Password must contain at least one special character.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-actions">
      <p-button label="Create" [disabled]="registerForm.invalid" type="submit" class="mr-2"></p-button> Already a member? <a href="/login"> Login</a>
    </div>
  </p-card>
</form>
<br />
<p-toast position="top-center"></p-toast>
