import { Component } from '@angular/core';
import { UserManagementToolbarComponent } from "../user-management-toolbar/user-management-toolbar.component";
import { SharePrimeNGModule } from '../../share-primeng.module';
import { Router } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserManagementService } from '../user.management.service';
import { error } from 'console';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [UserManagementToolbarComponent,SharePrimeNGModule,ReactiveFormsModule],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css',
  providers: [ConfirmationService, MessageService]
})
export class UsersComponent {
  users =[];
  pageSize: number = 10;
  totalItems : number = 0
  constructor(private router : Router, private userService: UserManagementService,
     private confirmationService: ConfirmationService, 
     private messageService: MessageService,
    ) {
    this.getUsers();
  }

  ngOnInit() {
    
  }

  onPage(event: any){
    const page = Math.floor(event.first / event.rows) + 1;
    this.getUsers(page)
  }

  navigate(){
    this.router.navigate(['/new-user'])
  }

  onEnter(event: any): void {
    const searchKeyword = typeof event === 'string' ? event : event.target.value.trim();
    this.getUsers(1, searchKeyword);
    if (event.target) {
      event.target.value = '';
    }
  }

  getUsers(page : number = 1, searchKeyword: string =''){
    this.userService.getUsers(page,this.pageSize,searchKeyword).subscribe({
      next: (response : any)=>{
        this.users = response.users;
        this.totalItems = response.totalItems;
      },
      error: (error)=>{

      }
    })
  }

  openDeleteDialog(user : any){
    this.confirmationService.confirm({
      message: 'Do you want to delete this user?',
      accept: () => {
        this.userService.deleteUser(user.id).subscribe({
          next: (response) => {
            this.getUsers();
          },
          error: (err: any) => {
            this.messageService.add({
              severity: 'error',
              summary: err.error,
              detail: err.messages,
              sticky: true,
            });
          }
        })
      }
    });
    
  }

  openUserEdit(user: any){
    this.router.navigate([`/edit-user/${user.id}`]);
  }
}