import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { EZHttpInterceptor } from './helpers/http.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';

export const httpInterceptorProviders=[
  {
    provide: HTTP_INTERCEPTORS, useClass : EZHttpInterceptor, multi:true
  },
  {
    provide: HTTP_INTERCEPTORS, useClass : ErrorInterceptor, multi:true
  }
]

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes), provideClientHydration(), provideAnimationsAsync(),
    //provideHttpClient(withFetch())
    provideHttpClient(withFetch(),withInterceptorsFromDi()),
    //importProvidersFrom(HttpClientModule),
    httpInterceptorProviders
  ]
};


